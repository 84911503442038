import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const SoftwareDevelopment = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <div className="software-wrapper">
      <div className="design-container">
        <h1 data-aos="zoom-in">SOFTWARE DEVELOPMENT</h1>
        <img src={Images.softwareDevelopmentImage} alt="Software Development" className="image" data-aos="zoom-in" />
      </div>

      <div className="new-div">
        <div className="left-content">
          <div className="card" data-aos="zoom-in">
            <h2>Software Development</h2>
            <p className="animated-text">
              We all are living in the 21st century, and this era is the era of Information Technology, Websites, Online Earning, Digital Marketing, e-commerce, Software Development, Social Media Marketing, etc.
              <br />
              Mind Marq Private Limited is one of the top 10 Software Development companies in Mumbai. The Software Development Company is having minimal costs for its services, and it is providing on-time delivery of its all services. As it is one of the topmost Software Development Companies, it is having a highly skilled, professional, and experienced team for all its operations. <br />
            </p>
          </div>
          <div className="card" data-aos="zoom-in">
            <h2>Mind Marq Star Features</h2>
            <p className="animated-text">
              <span className="span-heading">Mobile App Development</span> <br />
              The Software Development Company has dedicated Mobile App Developers who can create both Android and iOS Mobile Applications. We help you in building applications for your every need, whether it is for your business or enterprises. We had already launched a number of successful applications previously and the clients were completely satisfied. <br />
              <br />
              <span className="span-heading">Web Development</span> <br />
              Having a website or serving a website is a trend nowadays and we are experts in keeping you trendy. We have a skilled team of web developers too, who are highly experienced in the same sectors. Mind Marq, the leading Software Development Company in Mumbai, has expert knowledge in web development and has already created a huge number of successful websites. <br />
              <br />
              <span className="span-heading">Mobile Games</span> <br />
              This is what we do! It is a huge mystery why people love gaming, why they are attracted or addicted to a game compared to any other application. Well, viewers love to play games and we love to create games.
              <br />
              <br />
              Be happy, be gaming!!
            </p>
          </div>
          <div className="card" data-aos="zoom-in">
            <h2>Importance of Software Engineering</h2>
            <p className="animated-text">
              <span className="span-heading">It Reduces Complexity</span> <br />
              Big software is always complex and difficult to develop. Software engineering has a great solution to decrease the complexity of building any application. Software engineering simplifies the issues. <br />
              <br />
              <span className="span-heading">To Minimize the Software's Cost</span> <br />
              Software building requires a lot of hard work. A lot of manpower is required to develop software with millions of lines of code. But in software engineering, programmers re-plan everything and reduce all those things that are not required. Hence, the cost of software production becomes less compared to any other software that does not use a software engineering approach. <br />
              <br />
              <span className="span-heading">Effectiveness</span> <br />
              Effectiveness comes if anything is made according to its standards. Software standards are a big focus of every Software Development Company, so it can be more effective. Software becomes more effective in performance with the help of software engineers.
            </p>
          </div>
        </div>
        <div className="right-content">
          <div className="form-card" data-aos="flip-right">
            <h2>Contact</h2>
            <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" placeholder="Name" required />

              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" placeholder="Email" required />

              <label htmlFor="phone">Phone:</label>
              <input type="tel" id="phone" name="phone" placeholder="Phone" required />

              <label htmlFor="message">Message:</label>
              <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

              <button type="submit">Submit</button>
            </form>
          </div>
          {/* Use the Popup component */}
          {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
        </div>
      </div>
    </div>
  );
};

export default SoftwareDevelopment;
