import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const HospitalManagementSystem = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="all-products-wrapper">
        <div className="design-container">
          <h1 data-aos="zoom-in">Hospital Management System</h1>
          <img src={Images.hospital} alt="Hospital Management System" className="images" data-aos="zoom-in" />
        </div>

        <div className="new-div">
          <div className="left-content">
            <div className="card" data-aos="zoom-in">
              <h2>About Hospital Management System</h2>
              <p className="explain animated-text">
                Hospital Management System is a complete and multi-functional hospital or medical center management system, built using ASP.NET Core. <br />
                <br />
                The system can provide an automated way of managing any hospital activities rather than a traditional system. Moreover, this system can operate regular hospital activities like inpatient, outpatient, billing, test, bed management, account sector, etc. accurately and efficiently. Also, it helps to generate daily, weekly, monthly, half-yearly, and yearly reports of sales, revenue, patients, lab tests, bed management, etc. as per your requirements easily. To see these reports, you
                need to click on some options. <br />
                <br />
                The most important thing is that an admin can observe everyone through the software. An internal messaging platform helps employees to communicate with each other as well. All in all, clinic management software helps you to be with trends, and it also gives you the chance to run your business administration digitally and very efficiently.
                <br />
              </p>
            </div>

            <div className="card" data-aos="zoom-in">
              <h2>Features</h2>
              <div className="card-features">
                <div className="hospital-feature-item animated-text">Registration of patient</div>
                <div className="hospital-feature-item animated-text">Patient Portal</div>
                <div className="hospital-feature-item animated-text">Check the schedule and set an appointment</div>
                <div className="hospital-feature-item animated-text">Consult with doctor</div>
                <div className="hospital-feature-item animated-text">Print prescription</div>
                <div className="hospital-feature-item animated-text">Patient case study</div>
                <div className="hospital-feature-item animated-text">Perform medical test</div>
                <div className="hospital-feature-item animated-text">Dynamic Invoice System</div>
                <div className="hospital-feature-item animated-text">Dynamic user account type</div>
                <div className="hospital-feature-item animated-text">Records of diagnostics and reports</div>
                <div className="hospital-feature-item animated-text">Company setup</div>
                <div className="hospital-feature-item animated-text">Currency setup</div>
                <div className="hospital-feature-item animated-text">User login history</div>
                <div className="hospital-feature-item animated-text">Dynamic dashboard</div>
                <div className="hospital-feature-item animated-text">Medicine History</div>
                <div className="hospital-feature-item animated-text">Import Medicine from CSV</div>
                <div className="hospital-feature-item animated-text">Medicine Inventory</div>
                <div className="hospital-feature-item animated-text">Sales Service Report</div>
              </div>
            </div>
          </div>

          <div className="right-content">
            <div className="form-card" data-aos="flip-right">
              <h2>Contact</h2>
              <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Name" required />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Email" required />

                <label htmlFor="phone">Phone:</label>
                <input type="tel" id="phone" name="phone" placeholder="Phone" required />

                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

                <button type="submit">Submit</button>
              </form>
            </div>
            {/* Use the Popup component */}
            {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default HospitalManagementSystem;
