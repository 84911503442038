import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const LeaveManagementSystem = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="all-products-wrapper">
        <div className="design-container">
          <h1 data-aos="zoom-in">Leave Management System</h1>
          <img src={Images.LM} alt="Leave Management System" className="images" data-aos="zoom-in" />
        </div>

        <div className="new-div">
          <div className="left-content">
            <div className="card" data-aos="zoom-in">
              <h3>About Leave Management System</h3>
              <p className="explain animated-text">
                Staff | Employee Leave Management System is a complete web application and collection of programs designed to assist an organization in administering its executive responsibilities on a day-to-day basis. All Hours is a cloud-based leave management system that enables you to efficiently manage, approve, and track leave requests and absences from any device. <br />
                <br />
                This app, Stop losing paper forms, Reduces administration, Time-off accruals, and much more. With All Hours you can easily track all types of leave, including annual leave, sick leave, public holidays, private or unpaid leave, or the usage of surplus hours. Absence types can be completely customized and counted in days or hours as paid or unpaid time, that way it’s easy to track work done from home and other types of remote work.
              </p>
            </div>

            <div className="card" data-aos="zoom-in">
              <h3 className="heading2">Core Features</h3>
              <p className="explain animated-text">
                <span className="span-heading">Leave Application:- </span> <br />
                As an employee user I can raise leave application by selecting leave type using this system. And approver user can approve or reject it after reviewing the leave application.
                <br />
                <br />
                <span className="span-heading">Dashboard:- </span>
                <br />
                Dynamic dashboard with recent leave application and recent registered employee.
                <br />
                <br />
                <span className="span-heading">Reports:- </span> <br />
                Get comprehensive insights into your organization with detailed reports designed for you.
                <br />
                <br />
                <span className="span-heading"> Admin LTE Theme:-</span> <br />
                Use the excellent and responsive theme in a low ambient light environment to reduce eyestrain. It provides a range of responsive, reusable, and commonly used components.
                <br />
                <br />
                <span className="span-heading">Settings:- </span> <br />
                Easily manage the app with settings for logo, system default data, variants, custom page access, and identity option.
                <br />
                <br />
                <span className="span-heading">Much more:- </span> <br />
                User login history, user management, email configuration, nice UI.
                <br />
              </p>
            </div>

            <div className="card" data-aos="zoom-in">
              <h3 className="heading2">Core Business Features</h3>
              <div className="card-features">
                <div className="lm-feature-item animated-text">Employee management</div>
                <div className="lm-feature-item animated-text">Leave application management</div>
                <div className="lm-feature-item animated-text">Leave approval management</div>
                <div className="lm-feature-item animated-text">Financial Reporting and Analytics</div>
                <div className="lm-feature-item animated-text">Employee attendance management</div>
                <div className="lm-feature-item animated-text">Attendance report</div>
              </div>
            </div>
          </div>

          <div className="right-content">
            <div className="form-card" data-aos="flip-right">
              <h2>Contact</h2>
              <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Name" required />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Email" required />

                <label htmlFor="phone">Phone:</label>
                <input type="tel" id="phone" name="phone" placeholder="Phone" required />

                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

                <button type="submit">Submit</button>
              </form>
            </div>
            {/* Use the Popup component */}
            {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveManagementSystem;
