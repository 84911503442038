import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
import logo from "../../assets/navbar/logo.png";

const Navbar = () => {
  return (
    <nav>
      <div className="wrapper">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Mind Marq Technologies" />
          </Link>
        </div>
        <input type="radio" name="slider" id="menu-btn" />
        <input type="radio" name="slider" id="close-btn" />
        <ul className="nav-links">
          <label htmlFor="close-btn" className="btn close-btn">
            <i className="fas fa-times"></i>
          </label>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/services" className="desktop-item">
              Services
            </Link>
            <input type="checkbox" id="showDrop" />
            <label htmlFor="showDrop" className="mobile-item">
              Services
            </label>
            <ul className="drop-menu">
              <li>
                <Link to="/services/apps-development">Apps Development</Link>
              </li>
              <li>
                <Link to="/services/bi-dashboard">BI Dashboards</Link>
              </li>
              <li>
                <Link to="/services/digital-marketing">Digital Marketing</Link>
              </li>
              <li>
                <Link to="/services/seo-optimization">SEO Optimization</Link>
              </li>
              <li>
                <Link to="/services/software-development">Software Development</Link>
              </li>
              <li>
                <Link to="/services/website-development">Website Development</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/clients">Clients</Link>
          </li>
          <li>
            <Link to="/products" className="desktop-item">
              Products
            </Link>
            <input type="checkbox" id="showMega" />
            <label htmlFor="showMega" className="mobile-item">
              Products
            </label>
            <div className="mega-box">
              <div className="content">
                <div className="row">
                  <img src={logo} alt="Mind Marq Technologies" />
                </div>
                <div className="row">
                  <ul className="mega-links">
                    <li>
                      <Link to="/products/asset-management-system">Asset Management System</Link>
                    </li>
                    <li>
                      <Link to="/products/barcode-generator">Barcode Generator</Link>
                    </li>
                    <li>
                      <Link to="/products/business-erp-solution">Business ERP Solution</Link>
                    </li>
                    <li>
                      <Link to="/products/dental-clinic-management-system">Dental Clinic Management </Link>
                    </li>
                    <li>
                      <Link to="/products/employee-management-hrm">Employee Management</Link>
                    </li>
                    <li>
                      <Link to="/products/advanced-files-&-users-management">Files & Users Management</Link>
                    </li>
                    <li>
                      <Link to="/products/helpdesk-support-system">HelpDesk Support System</Link>
                    </li>
                    <li>
                      <Link to="/products/hospital-management-system">Hospital Management </Link>
                    </li>
                  </ul>
                </div>
                <div className="row">
                  <ul className="mega-links">
                    <li>
                      <Link to="/products/hotel-room-system">Hotel Room System</Link>
                    </li>
                    <li>
                      <Link to="/products/human-resource-management">Human Resource Management</Link>
                    </li>
                    <li>
                      <Link to="/products/inventory-management-and-user-management">Inventory User Management</Link>
                    </li>
                    <li>
                      <Link to="/products/laundry-box-pos-and-order-management-system">Laundry Box and Order System</Link>
                    </li>
                    <li>
                      <Link to="/products/leave-management-system">Leave Management System</Link>
                    </li>
                    <li>
                      <Link to="/products/library-management-system">Library Management System</Link>
                    </li>
                    <li>
                      <Link to="/products/multi-vendor-feedback-survey-system">MultiVendor Feedback Survey</Link>
                    </li>
                    <li>
                      <Link to="/products/parking-administration-system">Parking Administration</Link>
                    </li>
                  </ul>
                </div>
                <div className="row">
                  <ul className="mega-links">
                    {/* <li>
                      <Link to="/products/advance-pos-system-with-stock-manager">POS with Stock Manager</Link>
                    </li> */}
                    <li>
                      <Link to="/products/pharmacy-management-system">Pharmacy management</Link>
                    </li>
                    <li>
                      <Link to="/products/point-of-sale-pos-for-restaurants">Point of sale for restaurants</Link>
                    </li>
                    <li>
                      <Link to="/products/point-of-sale(pos)-pharmacy">Point Of Sale-Pharmacy</Link>
                    </li>
                    <li>
                      <Link to="/products/project-management-system">Project Management System</Link>
                    </li>
                    <li>
                      <Link to="/products/school-college-university-management-erp">School College Management</Link>
                    </li>
                    <li>
                      <Link to="/products/user-management">User Management</Link>
                    </li>
                    <li>
                      <Link to="/products/user-management-and-realtime-chat-app">Realtime Chat App</Link>
                    </li>
                    <li>
                      <Link to="/products/whatsapp-auto-reply-bot">WhatsApp Auto Reply Bot</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li>
            <Link to="/blogs">Blogs</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>

          <div className="cta-button">
            <button className="cta-btn">
              <a href="https://wa.me/918451046210" target="_blank" rel="noopener noreferrer">
                Chat With Us
              </a>
            </button>
          </div>
        </ul>
        <label htmlFor="menu-btn" className="btn menu-btn">
          <i className="fas fa-bars"></i>
        </label>
      </div>
    </nav>
  );
};

export default Navbar;
