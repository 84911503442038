import React from "react";
import "./style.css";
import Images from "../Images/Images";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <div className="services-wrapper">
      <div className="service-container">
        <div className="container-xxl py-5 bg-primary hero-about">
          <div className="container my-5 py-5 px-lg-5">
            <div className="row g-5 py-5">
              <div className="col-12 text-center">
                <h1 className="text-white services-line">
                  <div data-aos="flip-up">OUR SERVICES</div>
                </h1>

                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item text-white" aria-current="page">
                    Learn More About Our Services
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="services-card" data-aos="zoom-in">
          <div className="services-left">
            <h2>Website Development</h2>
            <p className="animated-text services-para">Creating modern, responsive websites that enhance user experience and performance. Our solutions are designed to be visually appealing and highly functional. We focus on optimizing website speed, security, and scalability. Let us help you establish a strong online presence and reach your audience effectively.</p>
            <Link to="/services/website-development" className="service-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="services-right">
            <img src={Images.websiteDevelopmentImage} alt="WebsiteDevelopment" />
          </div>
        </div>

        <div className="services-card" data-aos="zoom-in">
          <div className="services-left">
            <h2>Software Development</h2>
            <p className="animated-text">Building custom software solutions that drive business efficiency and innovation. Our team delivers scalable and robust software tailored to your unique requirements. We specialize in creating applications that streamline operations and enhance productivity. Partner with us to leverage technology for your business success.</p>
            <Link to="/services/software-development" className="service-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="services-right">
            <img src={Images.softwareDevelopmentImage} alt="SoftwareDevelopment" />
          </div>
        </div>

        <div className="services-card" data-aos="zoom-in">
          <div className="services-left">
            <h2>Apps Development</h2>
            <p className="animated-text">Developing intuitive and feature-rich mobile and web applications tailored to your needs. Our apps are designed to provide a seamless user experience on all devices. We use the latest technologies to ensure high performance and security. Transform your ideas into powerful, user-friendly applications with our expertise.</p>
            <Link to="/services/apps-development" className="service-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="services-right">
            <img src={Images.appsDevelopmentImage} alt="AppsDevelopment" />
          </div>
        </div>

        <div className="services-card" data-aos="zoom-in">
          <div className="services-left">
            <h2>BI Dashboards</h2>
            <p className="animated-text">Designing interactive Business Intelligence dashboards for data-driven decisions. Our dashboards provide real-time insights and visualizations that are easy to interpret. We help you track key performance indicators and uncover trends. Empower your team with the information needed to make strategic business choices.</p>
            <Link to="/services/bi-dashboard" className="service-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="services-right">
            <img src={Images.biDashboardsImage} alt="BIDashboards" />
          </div>
        </div>

        <div className="services-card" data-aos="zoom-in">
          <div className="services-left">
            <h2>SEO Optimization</h2>
            <p className="animated-text">Enhancing your website's visibility with strategic SEO techniques. We optimize your content and structure to rank higher in search engine results. Our SEO services drive organic traffic and improve user engagement. Achieve long-term success with a tailored SEO strategy that aligns with your goals.</p>
            <Link to="/services/seo-optimization" className="service-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="services-right">
            <img src={Images.seoOptimizationImage} alt="SEOOptimization" />
          </div>
        </div>

        <div className="services-card" data-aos="zoom-in">
          <div className="services-left">
            <h2>Digital Marketing</h2>
            <p className="animated-text">Crafting comprehensive digital marketing strategies to elevate your brand online. We offer a full range of services, including social media, PPC, and email marketing. Our data-driven approach ensures campaigns are effective and yield results. Reach your target audience, boost conversions, and grow your business with our help.</p>
            <Link to="/services/digital-marketing" className="service-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="services-right">
            <img src={Images.digitalMarketingImage} alt="DigitalMarketing" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
