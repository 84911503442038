import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const BulkWhatsAppSender = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="all-products-wrapper">
        <div className="design-container">
          <h1 data-aos="zoom-in">
            Bulk WhatsApp Sender <br /> + Group Sender + <br /> WhatsApp Auto Reply Bot
          </h1>
          <img src={Images.WARB} alt="Bulk WhatsApp Sender + Group Sender + WhatsApp Auto Reply Bot" className="images" data-aos="zoom-in" />
        </div>

        <div className="new-div">
          <div className="left-content">
            <div className="card" data-aos="zoom-in">
              <h3>About Bulk WhatsApp Sender + Group Sender + WhatsApp Auto Reply Bot</h3>
              <p className="explain animated-text">WaSender is a powerful, feature-rich tool designed to enhance your WhatsApp communication experience. It combines multiple functionalities into a single platform, allowing users to send bulk messages, manage group messaging, and automate replies on WhatsApp. This makes it an ideal solution for businesses, marketers, and individuals who need to efficiently reach a large audience or manage extensive conversations on WhatsApp.</p>
            </div>

            <div className="card" data-aos="zoom-in">
              <h3 className="heading2">Core Features</h3>
              <p className="explain animated-text">
                <span className="span-heading">Unlimited WhatsApp Messages to Contact / Numbers:- </span> <br />
                Send messages to all contacts including contacts not saved in your address book.
                <br />
                <br />
                <span className="span-heading">Send Message to Groups:- </span> <br />
                Send unlimited messages to all your joined groups.
                <br />
                <br />
                <span className="span-heading">Message Delay Management:- </span> <br />
                Strong Delay Management to protect your whatsapp account from Ban.
                <br />
                <br />
                <span className="span-heading">Grab Group Links from web:- </span> <br />
                Grab Group all Links from any web page.
                <br />
                <br />
                <span className="span-heading"> Get All Member number from any group:-</span> <br />
                Grab Group members from ny group.
                <br />
                <br />
                <span className="span-heading">Modern Material Design:- </span> <br />
                Latest Material UI design with easy to use interface.
                <br />
                <br />
                <span className="span-heading">Standard Coding Structure:- </span> <br />
                You can Customize this tool and add your features with simple coding skills.
                <br />
                <br />
                <span className="span-heading">Bulk Import:- </span> <br />
                Import you contact number from excel sheet.
                <br />
                <br />
                <span className="span-heading"> Google Map Data Extractor / Scrapper:-</span> <br />
                Extract data from google map with targeted location and import extracted numbers in WaSender, Send Bulk Messages to them.
                <br />
                <br />
                <span className="span-heading">Report:-</span> <br />
                You will get detailed report after campaign complete.
                <br />
                <br />
                <span className="span-heading">Documentations:- </span> <br />
                Detailed documents with images (with markups) and video.
                <br />
                <br />
                <span className="span-heading">Developer Documentations:-</span> <br />
                Detailed documents with images (with markups) and video to change Software name, logo and lot more.
                <br />
                <br />
                <span className="span-heading">Runnable Files:- </span> <br />
                We provided Runnable files.
                <br />
                <br />
                <span className="span-heading">Source Code:- </span> <br />
                We provided Code of this software to rebrand it with your name, logo and color scheme.
                <br />
                <br />
                <span className="span-heading">Lifetime updates:- </span> <br />
                We are keep enhancing our product and planning to intruduce cool features in future , stay with us.
                <br />
                <br />
                <span className="span-heading">License key Manager:- </span> <br />
                This software is protected with Activation Process, You will get ‘KeyGen’ along with this app Bundle.
                <br />
                <br />
                <span className="span-heading">Dynamic Parameterised Message Sender:-</span> <br />
                ie. ’ hi "Name" ’ , Your invoice no "InvoiceNo" is generated , Click below link to download "InvoiceLink" .<br />
              </p>
            </div>
          </div>

          <div className="right-content">
            <div className="form-card" data-aos="flip-right">
              <h2>Contact</h2>
              <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Name" required />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Email" required />

                <label htmlFor="phone">Phone:</label>
                <input type="tel" id="phone" name="phone" placeholder="Phone" required />

                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

                <button type="submit">Submit</button>
              </form>
            </div>
            {/* Use the Popup component */}
            {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default BulkWhatsAppSender;
