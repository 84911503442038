import React from "react";
import "./style.css";
import Images from "../Images/Images";

const Clients = ({ setProgress }) => {
  return (
    <div className="client-wrapper">
      <div className="container-xxl py-5 bg-primary hero-about">
        <div className="container my-5 py-5 px-lg-5">
          <div className="row g-5 py-5">
            <div className="col-12 text-center">
              <h1 className="text-white clients-line">
                <div data-aos="flip-up">CLIENTS</div>
              </h1>

              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item text-white" aria-current="page">
                  Get to know about our clients
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="clients-card" data-aos="zoom-in">
        <div className="right">
          <img src={Images.dattafibre} alt="MauryaService" />
        </div>
        <div className="left">
          <h1>Datta Fibre</h1>
          <p className="animated-text">Established in the year 2009 at Maharashtra, We "Datta Fibre" is a Sole Proprietorship based firm, engaged as the foremost Manufacturer of Fume Hood, Industrial Blower, Fume Hood Cabinet, FRP Pipe and much more. These products are offered by us most affordable rates.</p>

          <div className="button-container">
            <a href="https://dattafibre.com/" target="_blank" rel="noopener noreferrer" className="button">
              <img src={Images.getItOnWebsite} className="button-image" alt="Visit Maurya Service" />
            </a>
          </div>
        </div>
      </div>

      <div className="clients-card" data-aos="zoom-in">
        <div className="left">
          <h1>Krush Architects Consultants</h1>
          <p className="animated-text">A consultant architect provides design consultation services to their clients. They collaborate with clients to comprehend their design needs and offer specialized solutions that satisfy them. They help clients to choose suitable building materials, construction techniques, and building codes.</p>

          <div className="button-container">
            <a href="https://www.krusharchitectsconsultants.co.in/index.html" target="_blank" rel="noopener noreferrer" className="button">
              <img src={Images.getItOnWebsite} className="button-image" alt="VisitKrushArchitectsConsultants" />
            </a>
          </div>
        </div>
        <div className="right">
          <img src={Images.krushConsultant} alt="KrushArchitectsConsultants" />
        </div>
      </div>

      <div className="clients-card" data-aos="zoom-in">
        <div className="right">
          <img src={Images.Scc} alt="SaiChandanCoating" />
        </div>
        <div className="left">
          <h1>Sai Chandan Coating</h1>
          <p className="animated-text">Sai Chandan Coating is one of the leading companies in India in the field of metal finishing since 2013, serving its finest finishes possible on aluminium to provide the best to our customers, we have tied up with the world's largest paint companies - Akzo Nobel, Jotun, Asian PPG, Valspar, Nippon.</p>

          <div className="button-container">
            <a href="https://saichandancoating.com/" target="_blank" rel="noopener noreferrer" className="button">
              <img src={Images.getItOnWebsite} className="button-image" alt="VisitSaiChandanCoating" />
            </a>
          </div>
        </div>
      </div>

      <div className="clients-card" data-aos="zoom-in">
        <div className="left">
          <h1>Joy Raj Academy</h1>
          <p className="animated-text">Joy Raj Academy, where academic excellence meets personalized learning! We are dedicated to empowering students to reach their fullest potential through tailored education. Our experienced educators provide engaging instruction in a supportive environment, addressing each student’s unique strengths and challenges.</p>

          <div className="button-container">
            <a href="https://www.joyrajacademy.com/" target="_blank" rel="noopener noreferrer" className="button">
              <img src={Images.getItOnWebsite} className="button-image" alt="VisitJoyRajAcademy" />
            </a>
          </div>
        </div>
        <div className="right">
          <img src={Images.JoyRajAcademy} alt="JoyRajAcademy" />
        </div>
      </div>

      <div className="clients-card" data-aos="zoom-in">
        <div className="right">
          <img src={Images.hoh} alt="HouseOfHaki" />
        </div>
        <div className="left">
          <h1>House Of Haki</h1>
          <p className="animated-text">In Maharashtra, India, House Of Haki is the perfect location for aficionados of anime. Our shop, which is situated in Sangli, Maharashtra, sells a variety of anime action figures, apparel, accessories, posters, stickers, stationery, and much more. We offer something for you with the most affordable cost you can find buy, whether you're searching for your favourite superhero or anime character.</p>

          <div className="button-container">
            <a href="https://houseofhaki.in/" target="_blank" rel="noopener noreferrer" className="button">
              <img src={Images.getItOnWebsite} className="button-image" alt="VisitHouseOfHaki" />
            </a>
          </div>
        </div>
      </div>

      <div className="clients-card" data-aos="zoom-in">
        <div className="left">
          <h1>Asha Group Of Institutions</h1>
          <p className="animated-text">Asha Group of Management and Technologies, based in the vibrant city of Varanasi, is a leading educational institution committed to nurturing future leaders and professionals. Established with a vision to provide world-class education and skill development, we specialize in offering a diverse range of programs in management, technology, and vocational training.</p>

          <div className="button-container">
            <a href="http://www.agigroup.in/" target="_blank" rel="noopener noreferrer" className="button">
              <img src={Images.getItOnWebsite} className="button-image" alt="VisitAshaGroupOfInstitutions" />
            </a>
          </div>
        </div>
        <div className="right">
          <img src={Images.agi} alt="AshaGroupOfInstitutions" />
        </div>
      </div>
    </div>
  );
};

export default Clients;
