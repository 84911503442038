import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const SeoOptimization = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <div className="seo-wrapper">
      <div className="design-container">
        <h1 data-aos="zoom-in">SEO OPTIMIZATION</h1>
        <img src={Images.seoOptimizationImage} alt="SEO Optimization" className="seo-image" data-aos="zoom-in" />
      </div>

      <div className="new-div">
        <div className="left-content">
          <div className="card" data-aos="zoom-in">
            <h2>SEO Optimization</h2>
            <p className="explain animated-text">In the competitive digital landscape, Search Engine Optimization (SEO) is crucial for increasing visibility and driving organic traffic to your website. SEO is not just about ranking higher on search engines but also about improving the overall user experience and relevance of your site. Effective SEO strategies can enhance your online presence, attract potential customers, and boost your business's growth.</p>
          </div>
          <div className="card" data-aos="zoom-in">
            <h2>Our Services Include</h2>
            <div className="card-features">
              <div className="seo-feature-item animated-text">On-page and off-page SEO optimization</div>
              <div className="seo-feature-item animated-text">Technical SEO improvements</div>
              <div className="seo-feature-item animated-text">Content strategy and development</div>
              <div className="seo-feature-item animated-text">Link building and outreach</div>
            </div>
          </div>
          <div className="card" data-aos="zoom-in">
            <h2>Why Choose Us for SEO Optimization?</h2>
            <p className="explain animated-text">
              <span className="span-heading">Comprehensive SEO Strategies</span> <br />
              We develop and implement comprehensive SEO strategies that cover all aspects of optimization, from on-page and off-page SEO to technical improvements and content creation. <br />
              <br />
              <span className="span-heading">Keyword Research & Optimization</span> <br />
              Our team conducts thorough keyword research to identify the most relevant and high-performing keywords for your business. We then optimize your content and meta tags to improve your search engine rankings. <br />
              <br />
              <span className="span-heading">Content Quality & Relevance</span> <br />
              We focus on creating high-quality, relevant content that resonates with your target audience and aligns with search engine algorithms. This approach enhances user engagement and drives organic traffic. <br />
              <br />
              <span className="span-heading">Performance Tracking & Reporting</span> <br />
              We provide detailed performance reports and analytics to track the success of our SEO efforts. Our data-driven approach allows us to make informed adjustments and continually improve your search engine visibility. <br />
            </p>
          </div>
        </div>
        <div className="right-content">
          <div className="form-card" data-aos="flip-right">
            <h2>Contact</h2>
            <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" placeholder="Name" required />

              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" placeholder="Email" required />

              <label htmlFor="phone">Phone:</label>
              <input type="tel" id="phone" name="phone" placeholder="Phone" required />

              <label htmlFor="message">Message:</label>
              <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

              <button type="submit">Submit</button>
            </form>
          </div>
          {/* Use the Popup component */}
          {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
        </div>
      </div>
    </div>
  );
};

export default SeoOptimization;
