import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const PointOfSaleForRestaurants = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="all-products-wrapper">
        <div className="design-container">
          <h1 data-aos="zoom-in">
            Point of sale <br /> POS for restaurants
          </h1>
          <img src={Images.POSR} alt="Point of sale POS for restaurants" className="images" data-aos="zoom-in" />
        </div>

        <div className="new-div">
          <div className="left-content">
            <div className="card" data-aos="zoom-in">
              <h3 className="heading2">About Point of sale POS for restaurants</h3>
              <p className="explain animated-text">In general, POS systems are necessary for the restaurant industry. However, finding the right one can save you a handful of cash as well. Still, many restaurants use their system and software for basic needs without diving deeper into their options. You may be surprised to find that using your POS system when used to its full capacity, can save time and money. Check out these 3 money-saving options.</p>
            </div>

            <div className="card" data-aos="zoom-in">
              <h3 className="heading2">Features</h3>
              <div className="card-features">
                <div className="posr-feature-item animated-text">Smart Items collections and categories</div>
                <div className="posr-feature-item animated-text">beautiful GUI for easy usage</div>
                <div className="posr-feature-item animated-text">Graphical system for restaurant tables management</div>
                <div className="posr-feature-item animated-text">printing all types of checks in receipt printers</div>
                <div className="posr-feature-item animated-text">powerful reports for tracking sales cycle</div>
                <div className="posr-feature-item animated-text">accept multi-payment methods</div>
                <div className="posr-feature-item animated-text">working with the tax system</div>
                <div className="posr-feature-item animated-text">User’s privileges and permissions</div>
                <div className="posr-feature-item animated-text">multi-language system according to the user language</div>
                <div className="posr-feature-item animated-text">supporting the Arabic language</div>
              </div>
            </div>
          </div>

          <div className="right-content">
            <div className="form-card" data-aos="flip-right">
              <h2>Contact</h2>
              <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Name" required />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Email" required />

                <label htmlFor="phone">Phone:</label>
                <input type="tel" id="phone" name="phone" placeholder="Phone" required />

                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

                <button type="submit">Submit</button>
              </form>
            </div>
            {/* Use the Popup component */}
            {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default PointOfSaleForRestaurants;
