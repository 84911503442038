import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const LibraryManagementSystem = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="all-products-wrapper">
        <div className="design-container">
          <h1 data-aos="zoom-in">Library Management System</h1>
          <img src={Images.LMS} alt="Library Management System" className="images" data-aos="zoom-in" />
        </div>

        <div className="new-div">
          <div className="left-content">
            <div className="card" data-aos="zoom-in">
              <h3>About Library Management System</h3>
              <p className="explain animated-text">
                Library Management System is a complete web application and collection of programs designed to assist the librarian in administering its executive responsibilities on a day-to-day basis. Also, it operates your library with much more advantages than a traditional library. <br />
                <br />
                Core features are managing books, issuing books, and receiving a book by managing the proper history of the issued books. Also, librarians can track visitor’s logs as well. Additionally, the admin user can manage role-based user management.
              </p>
            </div>

            <div className="card" data-aos="zoom-in">
              <h3 className="heading2">Core Features</h3>
              <p className="explain animated-text">
                <span className="span-heading">Books Management:-</span> <br />
                Library management systems help libraries keep track of the books, their issue book checkouts, and members’ profiles.
                <br />
                <br />
                <span className="span-heading">Dashboard:- </span> <br />
                Dynamic dashboard with recent registered book, user and recent issue book items.
                <br />
                <br />
                <span className="span-heading">Admin LTE Theme:- </span> <br />
                Use the excellent and responsive theme in a low ambient light environment to reduce eyestrain. It provides a range of responsive, reusable, and commonly used components.
                <br />
                <br />
                <span className="span-heading">Settings:-</span> <br />
                Easily manage the app with settings for logo, book categories, variants, custom page access, and identity option.
                <br />
                <br />
                <span className="span-heading">Much more:- </span> <br />
                User login history, user management, email configuration, nice UI.
                <br />
              </p>
            </div>
          </div>

          <div className="right-content">
            <div className="form-card" data-aos="flip-right">
              <h2>Contact</h2>
              <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Name" required />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Email" required />

                <label htmlFor="phone">Phone:</label>
                <input type="tel" id="phone" name="phone" placeholder="Phone" required />

                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

                <button type="submit">Submit</button>
              </form>
            </div>
            {/* Use the Popup component */}
            {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default LibraryManagementSystem;
