import React from "react";
import "./style.css";

function About() {
  const iconStyle = {
    marginRight: "10px",
    color: "blue",
  };

  return (
    <>
      <div className="about-wrapper">
        <div className="container-xxl py-5 bg-primary hero-about">
          <div className="container my-5 py-5 px-lg-5">
            <div className="row g-5 py-5">
              <div className="col-12 text-center">
                <h1 className="text-white about-line">
                  <div data-aos="flip-up">ABOUT US</div>
                </h1>
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item text-white" aria-current="page">
                    Get To Know More About Mind Marq Technologies
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="about-text">
          <div>
            <div>
              <h3>The Best IT Solution With 5 Years of Experience</h3>
              <p className="about-card-text">We provide bespoke software solutions that solve real business problems and generate growth. That may sound complicated. In a nutshell, we like to fix and improve things and we create things from scratch that make your business operate smoothly.</p>
              <div className="about-details">
                <div className="about-feature">
                  <h4>
                    <i className="fas fa-check" style={iconStyle}></i>24/7 Support
                  </h4>
                  <h4>
                    <i className="fas fa-check" style={iconStyle}></i>Affordable Prices
                  </h4>
                </div>
                <div className="about-feature">
                  <h4>
                    <i className="fas fa-check" style={iconStyle}></i>Professional Staff
                  </h4>
                  <h4>
                    <i className="fas fa-check" style={iconStyle}></i>Updated Technology
                  </h4>
                </div>
              </div>
              <br />
              <br />
              <h4 className="about-info">
                <i className="fas fa-phone-alt" style={iconStyle}></i>
                <span style={{ color: "black" }}> Call to ask any question: </span> +91 8451046210
              </h4>
            </div>
          </div>
        </div>

        <div className="new-card">
          <div className="new-container">
            <h2>What makes us different? / Why us? / Why Mind Marq Tech?</h2>
            <p className="animated-text">
              We, at Mind Marq, believe in working in partnerships with our clients. Each of our clients is equally important to us and we collaborate, to understand their unique needs and working methods. We consistently interact and work together to create intelligent solutions that meet our client’s evolving business demands. And thus, provide quick turnarounds by following this collaborative style of working. We have been continuously bridging the gap between business and technology. <br />
              <br />
              We believe that communication is the key and our team of experts always follows this principle. We carry out constant communication between team members and clients, ensuring transparency. This also enables us to get greater insights as well as trust from all our clients. All of our projects are delivered at the stated times and our services come within a rather affordable range. <br />
              <br />
              Our development process is seamlessly carried out and it follows a systematic approach – We begin with signing a Non-Disclosure Agreement (NDA) with the client. Post which process and documentation is done. The responsibilities are shared among the team member to ensure a timely and smooth progression of the project. Proper scheduling is done of the whole process and we also carry out frequent reporting to keep everyone (including the clients) within the loop. We have a completely
              agile approach and get continuous client feedback and output, and accordingly change and progress with our development. This is why we can take on complex projects that other developers may perceive as difficult, making us one of the best software developers out there.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
