import React from "react";
import Images from "../Images/Images";
import "./style.css";

const CustomPopup = ({ message, onClose }) => {
  return (
    <div className="popup-container">
      <div className="popup-content">
        <span className="close-popup" onClick={onClose}>
          &times;
        </span>
        <img id="popupImage" src={Images.logo} alt="Success" className="popup-image" />
        <p style={{ textAlign: "center" }}>{message}</p>
        <div className="social-media-links">
          <a href="https://wa.me/918451046210" target="_blank" rel="noopener noreferrer">
            What's App
          </a>
          {" | "}
          <a href="https://www.linkedin.com/company/mind-marq-technologies" target="_blank" rel="noopener noreferrer">
            LinkedIn
          </a>
          {" | "}
          <a href="https://www.instagram.com/mindmarqtec?igsh=cXE3MTRld3B1bnBh" target="_blank" rel="noopener noreferrer">
            Instagram
          </a>
        </div>
      </div>
    </div>
  );
};

export default CustomPopup;
