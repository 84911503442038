import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const HotelRoomSystem = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="all-products-wrapper">
        <div className="design-container">
          <h1 data-aos="zoom-in">Hotel Room System</h1>
          <img src={Images.HMS} alt="Hotel Room System" className="images" data-aos="zoom-in" />
        </div>

        <div className="new-div">
          <div className="left-content">
            <div className="card" data-aos="zoom-in">
              <h3 className="heading2">About Hotel Room System</h3>
              <p className="explain animated-text">
                Looking for a powerful and intuitive hotel management software? Our cutting-edge multi-tier, multi-user Windows desktop application is just what you need! Developed using top-of-the-line C# and SQL database technologies, this software is built to streamline your hotel management processes and enhance your overall guest experience. With the ability to seamlessly switch between French and English languages, you can cater to a wide range of clientele. Say goodbye to the headaches of
                manual management and hello to the efficiency and ease of our hotel management software. Try it today!
              </p>
            </div>

            <div className="card" data-aos="zoom-in">
              <h3 className="heading2">Features</h3>
              <div className="card-features">
                <div className="hms-feature-item animated-text">HR Management</div>
                <div className="hms-feature-item animated-text">Hotel Configuration</div>
                <div className="hms-feature-item animated-text">Administrative</div>
                <div className="hms-feature-item animated-text">Settings</div>
                <div className="hms-feature-item animated-text">Archives</div>
                <div className="hms-feature-item animated-text">Permissions</div>
              </div>
            </div>
          </div>

          <div className="right-content">
            <div className="form-card" data-aos="flip-right">
              <h2>Contact</h2>
              <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Name" required />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Email" required />

                <label htmlFor="phone">Phone:</label>
                <input type="tel" id="phone" name="phone" placeholder="Phone" required />

                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

                <button type="submit">Submit</button>
              </form>
            </div>
            {/* Use the Popup component */}
            {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default HotelRoomSystem;
