import React from "react";
import "./style.css";
import Images from "../Images/Images";
import { Link } from "react-router-dom";

function Products() {
  return (
    <>
      <div className="product-wrapper">
        <div className="container-xxl py-5 bg-primary hero-about">
          <div className="container my-5 py-5 px-lg-5">
            <div className="row g-5 py-5">
              <div className="col-12 text-center">
                <h1 className="text-white products-line">
                  <div data-aos="flip-up">OUR PRODUCTS</div>
                </h1>
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item text-white" aria-current="page">
                    Get to know the cutting-edge products we offer for your success
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="products-card" data-aos="zoom-in">
          <div className="products-left">
            <h2>Business ERP Solution</h2>
            <p className="animated-text">
              Business ERP Solution/Product/POS/Company Management is an awesome business application, complete web solution to manage your business smartly. Business ERP solutions not only help you to manage your business but also you can completely keep track your sales, inventory, expenses, and purchase module with good historical and reporting data. It has a number of built-in reports to manage stock, payments, expenses, purchases, item bar code, employee attendance, etc.
            </p>
            <Link to="/products/business-erp-solution" className="products-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="right">
            <img src={Images.erp} alt="Business ERP Solution" />
          </div>
        </div>

        <div className="products-card" data-aos="zoom-in">
          <div className="products-left">
            <h2>School College University Management ERP</h2>
            <p className="animated-text">
              School/College/University Management ERP (Enterprise Resource Planning) is a complete web application and collection of programs designed to assist schools in administering their executive responsibilities on a day-to-day basis. Also, it operates your school with much more advantages than a traditional school. This school management system empowers schools to keep track of their daily operations while centralizing their resources and information. This managing system helps to
              reduce the pressure of managing a large amount of data from the school.
            </p>
            <Link to="/products/school-college-university-management-erp" className="products-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="right">
            <img src={Images.scums} alt="" />
          </div>
        </div>

        <div className="products-card" data-aos="zoom-in">
          <div className="products-left">
            <h2>Asset Management System</h2>
            <p className="animated-text">Asset Management System (AMS) is a multi-functional complete web application developed using ASP.NET Core and EF Core code first technology. It enables you to manage all kinds of assets for your company through proper monitoring and a dynamic reporting system. You can manage assets available in your office, like computers, Printers, Portable hard disks, pen drives, etc. You can track the history of assets as well.</p>
            <Link to="/products/asset-management-system" className="products-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="right">
            <img src={Images.ams} alt="" />
          </div>
        </div>

        <div className="products-card" data-aos="zoom-in">
          <div className="products-left">
            <h2>Hospital Management System</h2>
            <p className="animated-text">
              Hospital Management System is a complete and multi-functional hospital or medical center management system, built using ASP.NET Core. The most important thing is that an admin can observe everyone through the software. An internal messaging platform helps employees to communicate with each other as well. All in all, clinic management software helps you to be with trends, and it also gives you the chance to run your business administration digitally and very efficiently.
            </p>
            <Link to="/products/hospital-management-system" className="products-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="right">
            <img src={Images.hospital} alt="" />
          </div>
        </div>

        <div className="products-card" data-aos="zoom-in">
          <div className="products-left">
            <h2>Advance POS System with Stock Manager</h2>
            <p className="animated-text">Advance POS System with Stock Manager (APOS) is a multi-functional complete web application developed using ASP.NET Core and EF Core code first technology.</p>
            <Link to="/products/advance-pos-system-with-stock-manager" className="products-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="right">
            <img src={Images.posStock} alt="" />
          </div>
        </div>

        <div className="products-card" data-aos="zoom-in">
          <div className="products-left">
            <h2>Advanced Files & Users Management</h2>
            <p className="animated-text">Advanced Files & Users Management is a multi-functional complete web application developed using ASP.NET Core 6.0 and EF Core code first technology. This web application enables you to manage all kinds of documents with proper history, add interactive comments, and along with a dynamic dashboard.</p>
            <Link to="/products/advanced-files-&-users-management" className="products-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="right">
            <img src={Images.AFAUM} alt="" />
          </div>
        </div>

        <div className="products-card" data-aos="zoom-in">
          <div className="products-left">
            <h2>Inventory Management and User Management</h2>
            <p className="animated-text">Inventory Management System is ASP.NET Core (6.0), C#, EF code first web application that helps you to manage your all stock item, customer invoice, expense, supplier, dynamic reporting, user management, etc.</p>
            <Link to="/products/inventory-management-and-user-management" className="products-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="right">
            <img src={Images.IMAUM} alt="" />
          </div>
        </div>

        <div className="products-card" data-aos="zoom-in">
          <div className="products-left">
            <h2>Complaint Issue Helpdesk Ticket My Ticket HelpDesk Support System</h2>
            <p className="animated-text">Using these web applications you can create, update delete, read, and manage customer complaints smoothly with required data reporting, cool responsive UI, and firster paging, sorting, and searching functionality. Additionally, this system will store each user’s login history properly.</p>
            <Link to="./products/helpdesk-support-system" className="products-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="right">
            <img src={Images.HTS} alt="" />
          </div>
        </div>

        <div className="products-card" data-aos="zoom-in">
          <div className="products-left">
            <h2>Leave Management System</h2>
            <p className="animated-text">Staff | Employee Leave Management System is a complete web application and collection of programs designed to assist an organization in administering its executive responsibilities on a day-to-day basis. All Hours is a cloud-based leave management system that enables you to efficiently manage, approve, and track leave requests and absences from any device.</p>
            <Link to="/products/leave-management-system" className="products-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="right">
            <img src={Images.LM} alt="" />
          </div>
        </div>

        <div className="products-card" data-aos="zoom-in">
          <div className="products-left">
            <h2>User Management and Realtime Chat App</h2>
            <p className="animated-text">Building a real-time chat application with SignalR is a great way to learn about real-time web communication. SignalR is a library in ASP.NET that simplifies the process of adding real-time capabilities to web applications. In this project, I’ll provide an overview of how you can build a simple chat application using SignalR in ASP.NET Core.</p>
            <Link to="/products/user-management-and-realtime-chat-app" className="products-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="right">
            <img src={Images.UMARTCA} alt="" />
          </div>
        </div>

        <div className="products-card" data-aos="zoom-in">
          <div className="products-left">
            <h2>Laundry Box POS and Order Management System</h2>
            <p className="animated-text">A Laundry Box Point of Sale (POS) and Order Management System is a specialized software solution designed to streamline and manage the operations of a laundry service business. This system combines features of a traditional POS system with order management capabilities tailored to the specific needs of a laundry service.</p>
            <Link to="/products/laundry-box-pos-and-order-management-system" className="products-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="right">
            <img src={Images.LBAOMS} alt="" />
          </div>
        </div>

        <div className="products-card" data-aos="zoom-in">
          <div className="products-left">
            <h2>Library Management System</h2>
            <p className="animated-text">Library Management System is a complete web application and collection of programs designed to assist the librarian in administering its executive responsibilities on a day-to-day basis. Also, it operates your library with much more advantages than a traditional library.</p>
            <Link to="/products/library-management-system" className="products-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="right">
            <img src={Images.LMS} alt="" />
          </div>
        </div>

        <div className="products-card" data-aos="zoom-in">
          <div className="products-left">
            <h2>User Management</h2>
            <p className="animated-text">User Management is ASP.NET Core (8.0), IdentityCore, C#, EF code first web applications which help you to create, update delete, read, and manage user information very smoothly with cool UI and firster paging, sorting, and searching functionality. Along with each user’s login history.</p>
            <Link to="/products/user-management" className="products-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="right">
            <img src={Images.UM} alt="" />
          </div>
        </div>

        <div className="products-card" data-aos="zoom-in">
          <div className="products-left">
            <h2>Bulk WhatsApp Sender + Group Sender + WhatsApp Auto Reply Bot</h2>
            <p className="animated-text">WaSender is a powerful, feature-rich tool designed to enhance your WhatsApp communication experience. It combines multiple functionalities into a single platform, allowing users to send bulk messages, manage group messaging, and automate replies on WhatsApp. This makes it an ideal solution for businesses, marketers, and individuals who need to efficiently reach a large audience or manage extensive conversations on WhatsApp.</p>
            <Link to="/products/whatsapp-auto-reply-bot" className="products-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="right">
            <img src={Images.WARB} alt="" />
          </div>
        </div>

        <div className="products-card" data-aos="zoom-in">
          <div className="products-left">
            <h2>Barcode Generator</h2>
            <p className="animated-text">The Barcode Generator is a versatile and easy-to-use tool designed to create various types of barcodes for multiple applications. Whether you need barcodes for product labeling, inventory management, retail, or any other purpose, this tool simplifies the process by allowing users to generate high-quality barcodes in seconds. It supports a wide range of barcode formats, making it suitable for different industries and use cases.</p>
            <Link to="/products/barcode-generator" className="products-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="right">
            <img src={Images.BarcodeG} alt="" />
          </div>
        </div>

        <div className="products-card" data-aos="zoom-in">
          <div className="products-left">
            <h2>Point of sale POS for restaurants</h2>
            <p className="animated-text">In general, POS systems are necessary for the restaurant industry. However, finding the right one can save you a handful of cash as well. Still, many restaurants use their system and software for basic needs without diving deeper into their options. You may be surprised to find that using your POS system when used to its full capacity, can save time and money. Check out these 3 money-saving options.</p>
            <Link to="/products/point-of-sale-pos-for-restaurants" className="products-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="right">
            <img src={Images.POSR} alt="" />
          </div>
        </div>

        <div className="products-card" data-aos="zoom-in">
          <div className="products-left">
            <h2>Project Management System</h2>
            <p className="animated-text">Project Management System in asp.net MVC with ms SQL server Simple And Easy to use Project Management System Similar to Basecamp for the project owner to assign and take the progress of the task from remote workers</p>
            <Link to="/products/project-management-system" className="products-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="right">
            <img src={Images.PMS} alt="" />
          </div>
        </div>

        <div className="products-card" data-aos="zoom-in">
          <div className="products-left">
            <h2>PharmaX - Pharmacy management System</h2>
            <p className="animated-text">PharmaX – Pharmacy Management System for Small and medium business who wants to track their shop with stock, purchases, sales and generate sales report, purchase report and so many things</p>
            <Link to="/products/pharmacy-management-system" className="products-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="right">
            <img src={Images.PharmacyMS} alt="" />
          </div>
        </div>

        <div className="products-card" data-aos="zoom-in">
          <div className="products-left">
            <h2>HRM Ezzy- Human Resource Management</h2>
            <p className="animated-text">HRM Ezzy, is an ASP.NET Core web application, which will help you manage your company human resources management. It is also equipped with additional important feature to help your day to day HR business operation such as: Employee self service, attendance, leave, recruitment, performance, operation and payrolls.</p>
            <Link to="/products/human-resource-management" className="products-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="right">
            <img src={Images.HRM} alt="" />
          </div>
        </div>

        <div className="products-card" data-aos="zoom-in">
          <div className="products-left">
            <h2>Parking Administration System</h2>
            <p className="animated-text">The Parking Administration System is a comprehensive software solution designed to streamline the management of parking facilities. It provides a robust set of features for efficiently managing users, vehicles, parking spaces, and financial transactions. This system is ideal for parking lots, garages, and multi-level parking facilities, offering a user-friendly interface and extensive reporting capabilities.</p>
            <Link to="/products/parking-administration-system" className="products-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="right">
            <img src={Images.ParkingMS} alt="" />
          </div>
        </div>

        <div className="products-card" data-aos="zoom-in">
          <div className="products-left">
            <h2>Employee Management - HRM</h2>
            <p className="animated-text">The Employee Management - HRM (Human Resource Management) system is designed to streamline and automate various HR functions within an organization. It offers a comprehensive suite of features to manage employee information, track work hours, handle payroll, and generate detailed reports. With its intuitive user interface and robust functionality, this system helps HR departments efficiently manage and support their workforce.</p>
            <Link to="/products/employee-management-hrm" className="products-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="right">
            <img src={Images.EMS} alt="" />
          </div>
        </div>

        <div className="products-card" data-aos="zoom-in">
          <div className="products-left">
            <h2>Multi-vendor Feedback Survey System</h2>
            <p className="animated-text">In this product you have an admin panel from where you can create/manage survey group, create/manage survey and many more. This product provides various type of survey questions such as multiple choice, single choice, text entry questions etc. You can create survey in any form, it is very flexible.</p>
            <Link to="/products/multi-vendor-feedback-survey-system" className="products-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="right">
            <img src={Images.MVFS} alt="" />
          </div>
        </div>

        <div className="products-card" data-aos="zoom-in">
          <div className="products-left">
            <h2>Point Of Sale (POS) - Pharmacy</h2>
            <p className="animated-text">The Point Of Sale (POS) - Pharmacy system is a specialized software designed to streamline and enhance the sales and management processes within a pharmacy setting. It combines essential POS functionalities with features tailored specifically for the pharmaceutical industry, making it easier to manage inventory, track sales, handle customer transactions, and generate comprehensive reports.</p>
            <Link to="/products/point-of-sale(pos)-pharmacy" className="products-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="right">
            <img src={Images.posPharmacy} alt="" />
          </div>
        </div>

        <div className="products-card" data-aos="zoom-in">
          <div className="products-left">
            <h2>Hotel Room System</h2>
            <p className="animated-text">
              Looking for a powerful and intuitive hotel management software? Our cutting-edge multi-tier, multi-user Windows desktop application is just what you need! Developed using top-of-the-line C# and SQL database technologies, this software is built to streamline your hotel management processes and enhance your overall guest experience. With the ability to seamlessly switch between French and English languages, you can cater to a wide range of clientele. Say goodbye to the headaches of
              manual management and hello to the efficiency and ease of our hotel management software. Try it today!
            </p>
            <Link to="/products/hotel-room-system" className="products-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="right">
            <img src={Images.HMS} alt="" />
          </div>
        </div>

        <div className="products-card" data-aos="zoom-in">
          <div className="products-left">
            <h2>Dental Clinic Management System</h2>
            <p className="animated-text">The Dental Clinic Management System is a comprehensive software solution designed to streamline and enhance the operations of dental clinics. It provides a robust set of features for managing users, staff, patients, procedures, invoices, and reports. The system supports multiple PCs and includes additional tools for everyday tasks, ensuring that clinic operations run smoothly and efficiently.</p>
            <Link to="/products/dental-clinic-management-system" className="products-learn-more">
              Learn More
            </Link>
            <hr className="hr-line" />
          </div>
          <div className="right">
            <img src={Images.DCMS} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Products;
