import React, { useEffect } from "react";
import "./style.css";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const BackToTopAndWhatsApp = () => {
  useEffect(() => {
    const backToTopButton = $("#button");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 300) {
        backToTopButton.addClass("show");
      } else {
        backToTopButton.removeClass("show");
      }
    });

    backToTopButton.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });
  }, []);

  return (
    <>
      {/* <a id="button"></a> */}

      <a
        id="button"
        href="#top"
        aria-label="Back to Top"
        onClick={(e) => {
          e.preventDefault();
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      ></a>

      <a href="https://wa.me/918451046210" className="whatsapp-button" target="_blank" id="whatsappButton" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: "34px", cursor: "pointer", transition: "color 0.3s", color: "white" }} onMouseEnter={(e) => (e.currentTarget.style.color = "#333")} onMouseLeave={(e) => (e.currentTarget.style.color = "white")} />
      </a>
    </>
  );
};

export default BackToTopAndWhatsApp;
