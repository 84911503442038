import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const UserManagementAndRealtimeChatApp = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="all-products-wrapper">
        <div className="design-container">
          <h1 data-aos="zoom-in">
            User Management and <br /> Realtime Chat App
          </h1>
          <img src={Images.UMARTCA} alt="User Management and Realtime Chat App" className="images" data-aos="zoom-in" />
        </div>

        <div className="new-div">
          <div className="left-content">
            <div className="card" data-aos="zoom-in">
              <h3 className="heading2">About User Management and Realtime Chat App</h3>
              <p className="explain animated-text">Building a real-time chat application with SignalR is a great way to learn about real-time web communication. SignalR is a library in ASP.NET that simplifies the process of adding real-time capabilities to web applications. In this project, I’ll provide an overview of how you can build a simple chat application using SignalR in ASP.NET Core.</p>
            </div>

            <div className="card" data-aos="zoom-in">
              <h3 className="heading2">Features</h3>
              <div className="card-features">
                <div className="umartca-feature-item animated-text">Register New User</div>
                <div className="umartca-feature-item animated-text">Reset Password</div>
                <div className="umartca-feature-item animated-text">Admin Dashboard</div>
                <div className="umartca-feature-item animated-text">Recent Registered User</div>
                <div className="umartca-feature-item animated-text">Identity Setting</div>
                <div className="umartca-feature-item animated-text">Login History</div>
                <div className="umartca-feature-item animated-text">Live Chat / Real Time Messaging</div>
                <div className="umartca-feature-item animated-text">Super Admin panel to manage members</div>
                <div className="umartca-feature-item animated-text">Real-Time Message Delete</div>
                <div className="umartca-feature-item animated-text">Search Everywhere</div>
                <div className="umartca-feature-item animated-text">Header Notifications</div>
                <div className="umartca-feature-item animated-text">Reply to message</div>
                <div className="umartca-feature-item animated-text">File Attachment</div>
                <div className="umartca-feature-item animated-text">View Image Attachment</div>
                <div className="umartca-feature-item animated-text">Play Video Attachment</div>
                <div className="umartca-feature-item animated-text">Download Attachment File</div>
                <div className="umartca-feature-item animated-text">Real-time new message notifications</div>
                <div className="umartca-feature-item animated-text">Real-time user online and off-line status</div>
                <div className="umartca-feature-item animated-text">Built with ASP.NET</div>
                <div className="umartca-feature-item animated-text">ASP.NET Identity Authentication</div>
                <div className="umartca-feature-item animated-text">Audit Logs</div>
                <div className="umartca-feature-item animated-text">Multi-Language Support</div>
                <div className="umartca-feature-item animated-text">Dynamic Role Management</div>
                <div className="umartca-feature-item animated-text">Responsive Design</div>
              </div>
            </div>
          </div>

          <div className="right-content">
            <div className="form-card" data-aos="flip-right">
              <h2>Contact</h2>
              <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Name" required />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Email" required />

                <label htmlFor="phone">Phone:</label>
                <input type="tel" id="phone" name="phone" placeholder="Phone" required />

                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

                <button type="submit">Submit</button>
              </form>
            </div>
            {/* Use the Popup component */}
            {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserManagementAndRealtimeChatApp;
