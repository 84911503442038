import React, { useState } from "react";
import "./style.css";
import Images from "../Images/Images";
import submitContactForm from "../Api/Api";
import Popup from "../CustomPopup/CustomPopup";

const ContactSection = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <div className="contact-wrapper">
      <div className="container-xxl py-5 bg-primary hero-about">
        <div className="container my-5 py-5 px-lg-5">
          <div className="row g-5 py-5">
            <div className="col-12 text-center">
              <h1 className="text-white contact-line">
                <div data-aos="flip-up">CONTACT US</div>
              </h1>
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item text-white" aria-current="page">
                  If You Have Any Query, Feel Free To Contact Us
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-info" data-aos-delay="200">
        <div data-aos="zoom-in">
          <span>
            <i className="fas fa-mobile-alt"></i>
          </span>
          <span>Phone No.</span>
          <span className="text">+91 8451046210</span>
        </div>

        <div data-aos="zoom-in">
          <span>
            <i className="fas fa-envelope-open"></i>
          </span>
          <span>E-mail</span>
          <span className="text">connect@mindmarq.in</span>
        </div>

        <div data-aos="zoom-in">
          <span>
            <i className="fas fa-map-marker-alt"></i>
          </span>
          <span>Address</span>
          <span className="text">Mumbai, Maharashtra, India</span>
        </div>

        <div data-aos="zoom-in">
          <span>
            <i className="fas fa-clock"></i>
          </span>
          <span>Opening Hours</span>
          <span className="text">Monday - Friday (9:00 AM to 6:00 PM)</span>
        </div>
      </div>

      <div className="contact_form">
        <div className="contact_card" data-aos="flip-left">
          <form className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
            <h4 style={{ marginBottom: "20px" }}>Contact us</h4>
            <div>
              <input type="text" name="name" className="form-control name-field" placeholder="Name" required />
            </div>
            <div>
              <input type="email" name="email" className="form-control" placeholder="E-mail" required />
              <input type="text" name="phone" className="form-control" placeholder="Phone" required />
            </div>
            <textarea rows="5" name="message" placeholder="Enter your message" className="form-control" required></textarea>
            <input type="submit" className="send-btn" value="Submit" />
          </form>
        </div>
        <div className="contact_right" data-aos="zoom-in">
          <h3 className="contact_right_h3" data-aos="slide-up">
            GET IN TOUCH
          </h3>
          <div className="home-line">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <img src={Images.contactPng} alt="ContactImage" className="contact_contact_image" />
        </div>

        {/* Use the Popup component */}
        {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
      </div>
    </div>
  );
};

export default ContactSection;
