import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const PointOfSalePharmacy = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="all-products-wrapper">
        <div className="design-container">
          <h1 data-aos="zoom-in">
            Point Of Sale <br /> (POS) - Pharmacy
          </h1>
          <img src={Images.posPharmacy} alt="Point Of Sale (POS) - Pharmacy" className="images" data-aos="zoom-in" />
        </div>

        <div className="new-div">
          <div className="left-content">
            <div className="card" data-aos="zoom-in">
              <h3 className="heading2">About Point Of Sale (POS) - Pharmacy</h3>
              <p className="explain animated-text">The Point Of Sale (POS) - Pharmacy system is a specialized software designed to streamline and enhance the sales and management processes within a pharmacy setting. It combines essential POS functionalities with features tailored specifically for the pharmaceutical industry, making it easier to manage inventory, track sales, handle customer transactions, and generate comprehensive reports.</p>
            </div>

            <div className="card" data-aos="zoom-in">
              <h3 className="heading2">Features</h3>
              <div className="card-features">
                <div className="pospharmacy-feature-item animated-text">Users Management</div>
                <div className="pospharmacy-feature-item animated-text">System access privileges 1.- Administrator 2.- Employee</div>
                <div className="pospharmacy-feature-item animated-text">Manage Categories – Create, Update, Show, Delete (Category Name)</div>
                <div className="pospharmacy-feature-item animated-text">Manage Items – Create, Update, Show, Delete (item code, item name, purchase price, sale price, quantity, image)</div>
                <div className="pospharmacy-feature-item animated-text">Supports Barcode Reader</div>
                <div className="pospharmacy-feature-item animated-text">Record of Expenses</div>
                <div className="pospharmacy-feature-item animated-text">Automated Customer Registration and Filtration</div>
                <div className="pospharmacy-feature-item animated-text">Reset of Sales, Expenses and Customer Registration</div>
                <div className="pospharmacy-feature-item animated-text">Register Sales very easily</div>
                <div className="pospharmacy-feature-item animated-text">Register Sales with debt option</div>
                <div className="pospharmacy-feature-item animated-text">Manage Debts</div>
                <div className="pospharmacy-feature-item animated-text">Complete outstanding debt</div>
                <div className="pospharmacy-feature-item animated-text">Barcode creator</div>
                <div className="pospharmacy-feature-item animated-text">Sales Report</div>
                <div className="pospharmacy-feature-item animated-text">Sales of the day</div>
                <div className="pospharmacy-feature-item animated-text">Sales between date range</div>
                <div className="pospharmacy-feature-item animated-text">Sales Details</div>
                <div className="pospharmacy-feature-item animated-text">Debt Report</div>
                <div className="pospharmacy-feature-item animated-text">Report of Income and Expenses</div>
                <div className="pospharmacy-feature-item animated-text">Print Reports</div>
                <div className="pospharmacy-feature-item animated-text">Receipt Printing</div>
                <div className="pospharmacy-feature-item animated-text">Very nice and intuitive User Interface Design</div>
              </div>
            </div>
          </div>

          <div className="right-content">
            <div className="form-card" data-aos="flip-right">
              <h2>Contact</h2>
              <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Name" required />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Email" required />

                <label htmlFor="phone">Phone:</label>
                <input type="tel" id="phone" name="phone" placeholder="Phone" required />

                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

                <button type="submit">Submit</button>
              </form>
            </div>
            {/* Use the Popup component */}
            {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default PointOfSalePharmacy;
