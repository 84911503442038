import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const PharmacyManagementSystem = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="all-products-wrapper">
        <div className="design-container">
          <h1 data-aos="zoom-in">Pharmacy management System</h1>
          <img src={Images.PharmacyMS} alt="Pharmacy management System" className="images" data-aos="zoom-in" />
        </div>

        <div className="new-div">
          <div className="left-content">
            <div className="card" data-aos="zoom-in">
              <h3 className="heading2">About Pharmacy management System</h3>
              <p className="explain animated-text">PharmaX – Pharmacy Management System for Small and medium business who wants to track their shop with stock, purchases, sales and generate sales report, purchase report and so many things</p>
            </div>

            <div className="card" data-aos="zoom-in">
              <h3 className="heading2">Features</h3>
              <div className="card-features">
                <div className="pharmacyms-feature-item animated-text">Inventory Management</div>
                <div className="pharmacyms-feature-item animated-text">Sales Management</div>
                <div className="pharmacyms-feature-item animated-text">Purchase Management</div>
                <div className="pharmacyms-feature-item animated-text">Customer Management</div>
                <div className="pharmacyms-feature-item animated-text">Supplier Management</div>
                <div className="pharmacyms-feature-item animated-text">User Management</div>
              </div>
            </div>
          </div>

          <div className="right-content">
            <div className="form-card" data-aos="flip-right">
              <h2>Contact</h2>
              <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Name" required />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Email" required />

                <label htmlFor="phone">Phone:</label>
                <input type="tel" id="phone" name="phone" placeholder="Phone" required />

                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

                <button type="submit">Submit</button>
              </form>
            </div>
            {/* Use the Popup component */}
            {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default PharmacyManagementSystem;
