import React, { useState } from "react";
import "./style.css";
import Images from "../Images/Images";
import videoFile from "../../assets/home/video/videoFile.mp4";
import submitContactForm from "../Api/Api";
import Popup from "../CustomPopup/CustomPopup";

const Home = ({ setProgress }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="home-wrapper">
        <div className="main">
          <div className="page1">
            <div className="bottom-page1">
              <h2>
                Creative & Innovative <br /> Software Solutions
              </h2>
              <div className="bottom-page1-inner">
                <h4>We provide bespoke software solutions that solve real business problems and generate growth. That may sound complicated.</h4>
              </div>
            </div>
            <video src={videoFile} autoPlay loop muted></video>
          </div>
        </div>

        {/* Services Section  */}
        <div className="home-services">
          <h2 className="service-heading">BRIDGING THE GAP BETWEEN BUSINESS AND TECHNOLOGY</h2>
          <h2>OUR SERVICES</h2>
          <div className="home-line">
            <div></div>
            <div></div>
            <div></div>
          </div>

          <div className="home-services-container">
            <div className="home-services-cards">
              <div className="home-services-card" data-aos="zoom-in">
                <img src={Images.one} alt="ServiceImage" />
                <h4>Software Development</h4>
                <p>It is the tool or technique that helps companies make a healthy relationship with their customers by organizing the data.</p>
              </div>

              <div className="home-services-card" data-aos="zoom-in">
                <img src={Images.three} alt="ServiceImage" />
                <h4>Website Development</h4>
                <p>It involves building and maintaining the websites; it makes the website look great, works quickly with firm user experience.</p>
              </div>

              <div className="home-services-card" data-aos="zoom-in">
                <img src={Images.five} alt="ServiceImage" />
                <h4>Digital Marketing</h4>
                <p>Digital marketing uses online channels like social media, email, and ads to reach and engage customers. It aims to drive traffic, generate leads, and boost sales through targeted strategies.</p>
              </div>

              <div className="home-services-card" data-aos="zoom-in">
                <img src={Images.six} alt="ServiceImage" />
                <h4>BI Dashboards</h4>
                <p>It helps businesses monitor performance, analyze data, and make informed decisions. BI Dashboards typically include charts, graphs, and tables, providing a real-time overview of business operations and trends.</p>
              </div>

              <div className="home-services-card" data-aos="zoom-in">
                <img src={Images.two} alt="ServiceImage" />
                <h4>Apps Development</h4>
                <p>App development is the process of creating software applications for mobile devices, desktops, or web platforms. It involves designing the user interface (UI), coding the functionality, testing for performance and bugs, and deploying the app for users.</p>
              </div>

              <div className="home-services-card" data-aos="zoom-in">
                <img src={Images.four} alt="ServiceImage" />
                <h4>SEO Optimization</h4>
                <p>It involves techniques such as keyword research, optimizing website content, improving site speed, and building quality backlinks. The goal is to attract more organic traffic from search engines like Google by making the website more relevant and authoritative for targeted search queries.</p>
              </div>
            </div>
          </div>
        </div>
        {/* End Services Section  */}

        {/* Industries Section  */}
        <div className="industries">
          <h2>INDUSTRIES</h2>
          <div className="home-line">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="industries-container">
            <div className="industry" data-aos="zoom-in">
              <img src="https://www.techasoft.com/debug/img/cpu.svg" alt="TECHNOLOGY" />
              <h4>TECHNOLOGY</h4>
            </div>
            <div className="industry" data-aos="zoom-in">
              <img src="https://www.techasoft.com/debug/img/shopping-cart.svg" alt="ECOMMERCE" />
              <h4>ECOMMERCE</h4>
            </div>
            <div className="industry" data-aos="zoom-in">
              <img src="https://www.techasoft.com/debug/img/applications-1.svg" alt="BANKING" />
              <h4>BANKING</h4>
            </div>
            <div className="industry" data-aos="zoom-in">
              <img src="https://www.techasoft.com/debug/img/delivery-truck-2.svg" alt="LOGISTIC & SHIPPING" />
              <h4>LOGISTIC & SHIPPING</h4>
            </div>
            <div className="industry" data-aos="zoom-in">
              <img src="https://www.techasoft.com/debug/img/first-aid.svg" alt="HOSPITAL" />
              <h4>HOSPITAL</h4>
            </div>
            <div className="industry" data-aos="zoom-in">
              <img src="https://www.techasoft.com/debug/img/luggage.svg" alt="TRAVEL" />
              <h4>TRAVEL</h4>
            </div>
            <div className="industry" data-aos="zoom-in">
              <img src="https://www.techasoft.com/debug/img/book.svg" alt="EDUCATION" />
              <h4>EDUCATION</h4>
            </div>
            <div className="industry" data-aos="zoom-in">
              <img src="https://www.techasoft.com/debug/img/insurance-1.svg" alt="MANUFACTURING" />
              <h4>MANUFACTURING</h4>
            </div>
          </div>
        </div>
        {/* End Industries Section  */}

        {/* Technologies Section */}
        <div className="technologies">
          <h2>TECHNOLOGIES</h2>
          <div className="home-line">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="technologies-container">
            <div data-aos="zoom-out">
              <img src={Images.html} alt="HTML 5" />
            </div>
            <div data-aos="zoom-out">
              <img src={Images.css} alt="CSS 3" />
            </div>
            <div data-aos="zoom-out">
              <img src={Images.bootstrap} alt="Bootstrap" />
            </div>
            <div data-aos="zoom-out">
              <img src={Images.javascript} alt="JavaScript" />
            </div>
            <div data-aos="zoom-out">
              <img src={Images.react} alt="React" />
            </div>
            <div data-aos="zoom-out">
              <img src={Images.reactNative} alt="React Native" />
            </div>
            <div data-aos="zoom-out">
              <img src={Images.angular} alt="Angular" />
            </div>
            <div data-aos="zoom-out">
              <img src={Images.java} alt="Java" />
            </div>
            <div data-aos="zoom-out">
              <img src={Images.kotlin} alt="Kotlin" />
            </div>
            <div data-aos="zoom-out">
              <img src={Images.flutter} alt="Flutter" />
            </div>
            <div data-aos="zoom-out">
              <img src={Images.php} alt="PHP" />
            </div>
            <div data-aos="zoom-out">
              <img src={Images.NET} alt="Asp.net" />
            </div>
            <div data-aos="zoom-out">
              <img src={Images.api} alt="Api" />
            </div>
            <div data-aos="zoom-out">
              <img src={Images.python} alt="Python" />
            </div>
            <div data-aos="zoom-out">
              <img src={Images.django} alt="Django" />
            </div>
          </div>
        </div>
        {/* End Technologies Section  */}

        {/* Clients Section  */}
        <div className="clients">
          <h2>OUR CLIENTS</h2>
          <div className="home-line">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="clients-container">
            <picture data-aos="flip-up">
              <img src={Images.dattafibre} alt="dattafibre" />
            </picture>

            <picture data-aos="flip-up">
              <img src={Images.krushConsultant} alt="krushConsultant" />
            </picture>

            <picture data-aos="flip-up">
              <img src={Images.Scc} alt="SAICHANDANCOATING" />
            </picture>

            <picture data-aos="flip-up">
              <img src={Images.JoyRajAcademy} alt="JoyRajAcademy" />
            </picture>

            <picture data-aos="flip-up">
              <img src={Images.hoh} alt="HouseOfHaki" />
            </picture>

            <picture data-aos="flip-up">
              <img src={Images.agi} alt="AgiGroup" />
            </picture>
          </div>
        </div>
        {/* End Clients Section  */}

        {/* Testimonials Section  */}
        {/* <div className="owl-slider">
        <h2 className="our-services">TESTIMONIALS</h2>
        <div className="testimonial-line">
          <div></div>
          <div></div>
          <div></div>
        </div>

        <div id="carousel" className="owl-carousel">
          <div className="item" data-aos="zoom-in-down">
            <div className="testimonial-card">
              <div className="stars">
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star-half"></i>
              </div>
              <div className="testimonial-content">
                <img src="../../assets/home/images/testimonials/avatar.png" alt="Avatar" />
                <div className="testimonial-text">
                  <h3 className="name">John Doe</h3>
                  <p className="role">Entrepreneur</p>
                </div>
              </div>
              <p className="message">Mind Marq delivers services on time with no compromise in quality. Analytical skills and responsiveness are the quality that I like the most.</p>
            </div>
          </div>

          <div className="item" data-aos="zoom-in-down">
            <div className="testimonial-card">
              <div className="stars">
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star-half"></i>
              </div>
              <div className="testimonial-content">
                <img src="../../assets/home/images/testimonials/avatar.png" alt="Avatar" />
                <div className="testimonial-text">
                  <h3 className="name">Mark Wood</h3>
                  <p className="role">Entrepreneur</p>
                </div>
              </div>
              <p className="message">Mind Marq has developed a good understanding of our own vision and work flows. I would easily recommend them for any of my projects. </p>
            </div>
          </div>

          <div className="item" data-aos="zoom-in-down">
            <div className="testimonial-card">
              <div className="stars">
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star-half"></i>
              </div>
              <div className="testimonial-content">
                <img src="../../assets/home/images/testimonials/avatar.png" alt="Avatar" />
                <div className="testimonial-text">
                  <h3 className="name">Dale</h3>
                  <p className="role">Entrepreneur</p>
                </div>
              </div>
              <p className="message"> I am very impressed by the quality of the team working on our project and the team displays a real understanding of our issues. </p>
            </div>
          </div>

          <div className="item">
            <div className="testimonial-card">
              <div className="stars">
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star-half"></i>
              </div>
              <div className="testimonial-content">
                <img src="../../assets/home/images/testimonials/avatar.png" alt="Avatar" />
                <div className="testimonial-text">
                  <h3 className="name">Trent Boult</h3>
                  <p className="role">Entrepreneur</p>
                </div>
              </div>
              <p className="message">I like the professionalism Mind Marq Pvt Ltd has and the companies attentive nature. They deliver the product on time.</p>
            </div>
          </div>
        </div>
      </div> */}
        {/* End Testimonials Section  */}

        {/* Milestones Section  */}
        <div className="milestones">
          <h2>MILESTONES</h2>
          <div className="home-line">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="milestones-container">
            <div data-aos="zoom-in">
              <div>
                <img src="https://www.techasoft.com/debug/img/star(2).svg" alt="" />
              </div>
              <div>6+</div>
              <div className="text">YEARS EXPERIENCE</div>
            </div>
            <div data-aos="zoom-in">
              <div>
                <img src="https://www.techasoft.com/debug/img/worldwide-1.svg" alt="" />
              </div>
              <div>100+</div>
              <div className="text">WEBSITE BUILT</div>
            </div>
            <div data-aos="zoom-in">
              <div>
                <img src="https://www.techasoft.com/debug/img/inbox.svg" alt="" />
              </div>
              <div>15+</div>
              <div className="text">HAPPY CUSTOMERS</div>
            </div>
          </div>
        </div>
        {/* End Milestones Section */}

        {/* Portfolio Section  */}
        <div className="portfolio">
          <h2>OUR PORTFOLIO</h2>
          <div className="home-line">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="portfolio-container">
            <a href="https://dattafibre.com/" target="_blank" rel="noreferrer" data-aos="zoom-in">
              {" "}
              <img src={Images.dattafibrep} alt="" />{" "}
            </a>
            <a href="https://www.krusharchitectsconsultants.co.in/index.html" target="_blank" rel="noreferrer" data-aos="zoom-in">
              {" "}
              <img src={Images.k} alt="" />{" "}
            </a>
            <a href="https://saichandancoating.com/" target="_blank" rel="noreferrer" data-aos="zoom-in">
              {" "}
              <img src={Images.s} alt="" />{" "}
            </a>
            <a href="https://www.joyrajacademy.com/" target="_blank" rel="noreferrer" data-aos="zoom-in">
              {" "}
              <img src={Images.j} alt="" />{" "}
            </a>
            <a href="https://houseofhaki.in/" target="_blank" rel="noreferrer" data-aos="zoom-in">
              {" "}
              <img src={Images.hohp} alt="" />{" "}
            </a>
            <a href="http://www.agigroup.in/" target="_blank" rel="noreferrer" data-aos="zoom-in">
              {" "}
              <img src={Images.agip} alt="" />{" "}
            </a>
          </div>
        </div>
        {/* End Portfolio Section  */}

        {/* Contact Section  */}
        <div className="home-contact-section" id="contact">
          <div className="home-contact-form" data-aos="flip-left">
            <h2>Share Your Query And Contact</h2>
            <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
              <input type="text" id="name" name="name" placeholder="Name" required />

              <input type="email" id="email" name="email" placeholder="Email" required />

              <input type="tel" id="phone" name="phone" pattern="[0-9]{10}" placeholder="Phone No" required />

              <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

              <button type="submit">Send</button>
            </form>
          </div>
          <div className="home-contact-image" data-aos="zoom-in">
            <h3 data-aos="slide-up">GET IN TOUCH</h3>
            <div className="home-line">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <img src={Images.contact} alt="Contact Us" className="home-contact-form-image" />
          </div>
          {/* Use the Popup component */}
          {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
        </div>
      </div>
      {/* < End Contact Section  */}
    </>
  );
};

export default Home;
