import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const AssetManagementSystem = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="all-products-wrapper">
        <div className="design-container">
          <h1 data-aos="zoom-in">Asset Management System</h1>
          <img src={Images.ams} alt="Asset Management System" className="images" data-aos="zoom-in" />
        </div>

        <div className="new-div">
          <div className="left-content">
            <div className="card" data-aos="zoom-in">
              <h3>About Asset Management System</h3>
              <p className="explain animated-text">Asset Management System (AMS) is a multi-functional complete web application developed using ASP.NET Core and EF Core code first technology. It enables you to manage all kinds of assets for your company through proper monitoring and a dynamic reporting system. You can manage assets available in your office, like computers, Printers, Portable hard disks, pen drives, etc. You can track the history of assets as well.</p>
            </div>

            <div className="card" data-aos="zoom-in">
              <h3 className="heading2">Core Features</h3>
              <p className="explain animated-text">
                <span className="span-heading">Software Inventory Management:- </span>
                <br />
                Manage all the software of your organizations with the Asset Management System. It provides you with a dashboard to input all the software purchases you have made. Update the license dates from when it was installed, to when the software will expire.
                <br />
                <br />
                <span className="span-heading">Hardware Inventory Management:- </span>
                <br />
                Supervise the status of your hardware and the employees’ using it with the help of the Asset Management System. Record all the data about the hardware like a purchaser, date of purchase, number of equipment bought, list of all the equipment acquired, et cetera. You can also see the employees who are assigned to use the hardware.
                <br />
                <br />
                <span className="span-heading"> Asset Life Cycle Management:-</span>
                <br />
                Track an asset life cycle in the organization with Asset Management System. Check for the date it was bought for and see how well the asset was utilized. Organizations are also enabled to see how many employees have used the asset for what amount of time. It just makes your asset management seamless.
                <br />
                <br />
                <span className="span-heading">Asset History:- </span>
                <br />
                It is a very simple feature that allows you to track any change in assets. Ex new assignment, asset status change, etc.
                <br />
                <br />
                <span className="span-heading">Supplier Management:- </span>
                <br />
                Asset Management System provides a module to manage all your Suppliers in one place. It also helps you with managing all suppliers.
                <br />
                <br />
                <span className="span-heading"> User Management:-</span>
                <br />
                Users are provided with admin accounts while registering their company. Admins can create IT Manager Accounts and others custom account by limited page access.
                <br />
              </p>
            </div>
          </div>

          <div className="right-content">
            <div className="form-card" data-aos="flip-right">
              <h2>Contact</h2>
              <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Name" required />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Email" required />

                <label htmlFor="phone">Phone:</label>
                <input type="tel" id="phone" name="phone" placeholder="Phone" required />

                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

                <button type="submit">Submit</button>
              </form>
            </div>
            {/* Use the Popup component */}
            {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default AssetManagementSystem;
