import React, { useEffect } from "react";
import "./style.css"; 

const SocialIcons = () => {
  const checkScrollPosition = () => {
    const scrollPosition = window.scrollY;
    const viewportHeight = window.innerHeight;
    const triggerPoint = viewportHeight * 0.3;

    const icons = document.querySelector(".icons");

    if (scrollPosition > triggerPoint) {
      icons.style.transform = "translate(0, 0)";
    } else {
      icons.style.transform = "translate(170px, 0)";
    }
  };

  useEffect(() => {
    checkScrollPosition();
    window.addEventListener("scroll", checkScrollPosition);
    window.addEventListener("resize", checkScrollPosition);

    return () => {
      window.removeEventListener("scroll", checkScrollPosition);
      window.removeEventListener("resize", checkScrollPosition);
    };
  }, []);

  return (
    <div className="icons">
      <a href="https://wa.me/918451046210" className="whatsapp" target="_blank" rel="noopener noreferrer" style={{ color: "white" }}>
        <i className="fa-brands fa-whatsapp"></i> what's app
      </a>
      <a href="https://www.instagram.com/mindmarqtec?igsh=cXE3MTRld3B1bnBh" className="instagram" target="_blank" rel="noopener noreferrer" style={{ color: "white" }}>
        <i className="fa-brands fa-instagram"></i> instagram
      </a>
      <a href="https://www.linkedin.com/company/mind-marq-technologies" className="linkedin" target="_blank" rel="noopener noreferrer" style={{ color: "white" }}>
        <i className="fa-brands fa-linkedin-in"></i> linkedin
      </a>
    </div>
  );
};

export default SocialIcons;
