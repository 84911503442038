import React from "react";
import "./style.css";
import Images from "../Images/Images";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="container-fluid bg-primary text-light custom-footer wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5 px-lg-5">
          <div className="row g-5">
            <div className="col-md-6 col-lg-3 from-top" style={{ marginTop: "90px" }}>
              <p className="section-title text-light h5 mb-4">
                Address<span></span>
              </p>
              <p>
                <i className="fa fa-map-marker-alt mx-1"></i>Mumbai, Maharashtra, India
              </p>
              <p>
                <i className="fa fa-phone-alt mx-1"></i>+91 8451046210
              </p>
              <p>
                <i className="fa fa-envelope mx-1"></i>connect@mindmarq.in
              </p>
              <div className="d-flex pt-2">
                <a className="btn btn-outline-light btn-social" href="https://wa.me/918451046210" target="_blank" rel="noreferrer">
                  <i className="fab fa-whatsapp"></i>
                </a>
                <a className="btn btn-outline-light btn-social" href="https://www.instagram.com/mindmarqtec?igsh=cXE3MTRld3B1bnBh" target="_blank" rel="noreferrer">
                  <i className="fab fa-instagram"></i>
                </a>
                <a className="btn btn-outline-light btn-social" href="https://www.linkedin.com/company/mind-marq-technologies" target="_blank" rel="noreferrer">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-lg-3" style={{ marginTop: "90px" }}>
              <p className="section-title text-light h5 mb-4">
                Quick Link<span></span>
              </p>

              <Link to="/" className="btn btn-link">
                Home
              </Link>

              <Link to="/about" className="btn btn-link">
                About
              </Link>

              <Link to="/clients" className="btn btn-link">
                Clients
              </Link>

              <Link to="/blogs" className="btn btn-link">
                Blogs
              </Link>

              <Link to="/contact" className="btn btn-link">
                Contact
              </Link>
            </div>

            <div className="col-md-6 col-lg-3" style={{ marginTop: "90px" }}>
              <p className="section-title text-light h5 mb-4">
                Services Link<span></span>
              </p>

              <Link to="/services/apps-development" className="btn btn-link">
                Apps Development
              </Link>

              <Link to="/services/bi-dashboard" className="btn btn-link">
                BI Dashboards
              </Link>

              <Link to="/services/seo-optimization" className="btn btn-link">
                SEO Optimization
              </Link>

              <Link to="/services/software-development" className="btn btn-link">
                Software Development
              </Link>

              <Link to="/services/website-development" className="btn btn-link">
                Website Development
              </Link>
            </div>

            <div className="col-md-6 col-lg-3">
              <p className="section-title text-light h5 mb-4">
                <a href="/">
                  <img src={Images.logo1} className="footer-image" alt="Company Logo" />
                </a>
              </p>
              <p className="info">We provide bespoke software solutions that solve real business problems and generate growth. That may sound complicated. In a nutshell, we like to fix and improve things and we create things from scratch that make your business operate smoothly.</p>
            </div>
          </div>
        </div>
        <div className="container px-lg-5">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 text-center text-md-center mb-3 mb-md-0">
                &copy; 2024
                <a className="text-decoration-none" href="/">
                  , Mind Marq Technologies
                </a>
                , All Right Reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
