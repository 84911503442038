import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const HRMEzzy = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="all-products-wrapper">
        <div className="design-container">
          <h1 data-aos="zoom-in">
            HRM Ezzy- Human <br /> Resource Management
          </h1>
          <img src={Images.HRM} alt="HRM Ezzy- Human Resource Management" className="images" data-aos="zoom-in" />
        </div>

        <div className="new-div">
          <div className="left-content">
            <div className="card" data-aos="zoom-in">
              <h3>About HRM Ezzy- Human Resource Management</h3>
              <p className="explain animated-text">
                HRM Ezzy, is an ASP.NET Core web application, which will help you manage your company human resources management. It is also equipped with additional important feature to help your day to day HR business operation such as: Employee self service, attendance, leave, recruitment, performance, operation and payrolls. <br />
                <br />
                We understand as programmer, we want full control of our software, that’s why we shipped the product with full source code so it can be customized and enhanced. Developed based on famous ASP.NET free open source project starter platform: SERENITY.IS Framework (with thousands liked stars at Github from programmers around the world), make this product a perfect choice for developers who seek great software architecture with simple project structure. Consist only one single project
                with modularity separated by folders, make the architecture easy to follow.
              </p>
            </div>

            <div className="card" data-aos="zoom-in">
              <h3>Most Requested Features</h3>
              <p className="explain animated-text">
                <span className="span-heading">Reach out to the world</span> <br />
                The product fully equipped with the most requested features that complete its main human resource management features, such as:- <br />
                Beautiful & Professional Theme <br />
                Beautiful & Compact Dashboard <br />
                Easy RBAC (Role Based Access Control) <br />
              </p>
            </div>

            <div className="card" data-aos="zoom-in">
              <h3>Functional Features</h3>
              <p className="explain animated-text">
                Dashboards <br />
                Employee Self Service > Punch In <br />
                Employee Self Service > Punch Out <br />
                Employee Self Service > Overtime <br />
                Employee Self Service > Timesheet <br />
                Employee Self Service > Leave Request <br />
                Employee Self Service > Reimbursement <br />
                Employee Self Service > Ticket <br />
                Employee Self Service > Meeting <br />
                Employee Self Service > Training <br />
                Employee Self Service > Business Trip <br />
                Employee Self Service > Complaint <br />
                Employee Self Service > Warning <br />
                Employee Self Service > Award <br />
                Employee Self Service > Announcement <br />
                Attendance > Punch In <br />
                Attendance > Punch Out <br />
                Attendance > Overtime <br />
                Attendance > Timesheet <br />
                Leave > Leave Type <br />
                Leave > Leave Request <br />
                Recruitment > Vacancy <br />
                Recruitment > Applicant <br />
                Recruitment > Interview <br />
                Performance > Evaluation <br />
                Performance > Appraisal <br />
                Performance > Promotion <br />
                Performance > Transfer <br />
                Operation > Termination <br />
                Operation > Reimbursement <br />
                Operation > Ticket <br />
                Operation > Meeting <br />
                Operation > Training <br />
                Operation > Business Trip <br />
                Operation > Asset <br />
                Operation > Asset Hand Over <br />
                Operation > Complaint <br />
                Operation > Event <br />
                Operation > Holiday <br />
                Operation > Award <br />
                Operation > Resignation <br />
                Operation > Warning <br />
                Operation > Announcement <br />
                Operation > SOP <br />
                Payrolls > Income <br />
                Payrolls > Deduction <br />
                Payrolls > Grade <br />
                Payrolls > Employee <br />
                Payrolls > Payroll <br />
                Organization > Designation <br />
                Organization > Department <br />
                Organization > Branch <br />
                Settings > My Company <br />
                Settings > Gender <br />
                Administration > Role <br />
                Administration > User <br />
              </p>
            </div>
          </div>

          <div className="right-content">
            <div className="form-card" data-aos="flip-right">
              <h2>Contact</h2>
              <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Name" required />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Email" required />

                <label htmlFor="phone">Phone:</label>
                <input type="tel" id="phone" name="phone" placeholder="Phone" required />

                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

                <button type="submit">Submit</button>
              </form>
            </div>
            {/* Use the Popup component */}
            {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default HRMEzzy;
