import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const BusinessERP = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="all-products-wrapper">
        <div className="design-container">
          <h1 data-aos="zoom-in">Business ERP Solution</h1>
          <img src={Images.erp} alt="Business ERP Solution" className="images" data-aos="zoom-in" />
        </div>
        <div className="new-div">
          <div className="left-content">
            <div className="card" data-aos="zoom-in">
              <h3>Taking Businesses Forward</h3>
              <p className="explain animated-text">
                Business ERP Solution/Product/POS/Company Management is an awesome business application, complete web solution to manage your business smartly. Business ERP solutions not only help you to manage your business but also you can completely keep track your sales, inventory, expenses, and purchase module with good historical and reporting data. It has a number of built-in reports to manage stock, payments, expenses, purchases, item bar code, employee attendance, etc. <br />
                <br />
                There are plenty of interesting features that will definitely boost your business up. Those features are going to make your work so easily and give you ultimate control over your entire business. Update your stock information, make purchases and view sales data from anywhere whether in the office, at home, in the warehouse, or on the go. All you need to access this a device with internet connection. Business ERP Solution/Product/POS/Company Management is a .NET Core/jQuery-based
                web application that allows you to manage your sales, expense, purchase and inventory from anywhere.
              </p>
            </div>

            <div className="card" data-aos="zoom-in">
              <h3 className="heading2">Core Features</h3>
              <p className="explain animated-text">
                <span class="span-heading">Sales:-</span> <br />
                Superfast invoice generation and print invoices with multiple options will definitely add another dimension to your business. Also, you can instantly share invoices through email instantly. <br />
                <br />
                <span class="span-heading">Inventory:- </span> <br />
                Fully integrated inventory module with this application. You can add, edit and manage your inventory item super easily. You can check every item’s transaction history. All items have a dynamic barcode tag. <br />
                <br />
                <span class="span-heading">POS:- </span> <br />
                Point Of Sale (POS) module has been integrated with this application. You can easily sell your items and generate invoices so quickly. <br />
                <br />
                <span class="span-heading">Expense:- </span> <br />
                Company all-expense you can manage using this application. Have multiple reports to track company expenses properly. <br />
                <br />
                <span class="span-heading">Purchase:- </span> <br />
                You can track all of your purchase histories using this application so quickly. This module automatically adjusts the newly added items into your inventory. Have multiple reports to visualize the entire purchase module. <br />
                <br />
                <span class="span-heading">Dashboard:- </span> <br />
                With Overview & Best Seller Pie Chart, Latest Nine (Invoice Today, Invoice Monthly, Sales Today, Sales Monthly, Total Item, Total Item Quantity, Low Items, Out of Stock, Due Invoice) items summary.
                <br />
                <br />
                <span class="span-heading">Item Variations:- </span> <br />
                Create different sizes, categories, or any attributes you define for your items. You can track inventory down to the variation and even have different prices per variation. <br />
                <br />
                <span class="span-heading">Item modifiers:- </span> <br />
                Modify inventory item, customer invoice easily. Choose add-ons to dishes or how they’re prepared in one click. <br />
                <br />
                <span class="span-heading">Reports:- </span> <br />
                Get comprehensive insights into your business with 20+ detailed reports designed for you. Overview & item stock, product quantity and damage item, general profit and/or loss report, sales, purchases, customer, suppliers and staff user reports. <br />
                <br />
                <span class="span-heading">Currency Exchange:- </span> <br />
                Easily accept any Currency Denomination While making a Sale or Return. <br />
                <br />
                <span class="span-heading">Barcode Label Printing:- </span> <br />
                Easily create barcode labels for your products when they arrive in your store or on-demand. <br />
                <br />
                <span class="span-heading">Payment Type:- </span> <br />
                Credit Card, EBT/EBT Processing, Cash, Check, Gift cards you can define your own custom payment methods.
                <br />
                <br />
                <span class="span-heading">Serial Numbers:- </span> <br />
                You can now manage items with serial numbers, relation with barcode and optionally you can also assign different prices depending on the serial number. <br />
                <br />
                <span class="span-heading"> Custom Receipts:- </span>
                <br />
                Easily customize your receipts to match what you need for your business. There are 5 different configuration options for making your receipts how you want them. <br />
                <br />
                <span class="span-heading"> Inventory history:-</span> <br />
                Gain insights into the flow of your inventory by viewing the adjustment log.
                <br />
                <br />
                <span class="span-heading"> Admin LTE Theme:-</span> <br />
                Use the excellent and responsive theme in a low ambient light environment to reduce eyestrain. It provides a range of responsive, reusable, and commonly used components. <br />
                <br />
                <span class="span-heading">People:- </span> <br />
                Manager, staff users, billers (selling companies), customers, suppliers and employees. <br />
                <br />
                <span class="span-heading">Settings:- </span> <br />
                Easily manage the app with settings for logo, categories, variants, currencies, custom page access, and identity option. <br />
                <br />
                <span class="span-heading"> Much more:-</span> <br />
                User login history, user management, email configuration, nice UI. <br />
              </p>
            </div>
          </div>

          <div className="right-content">
            <div className="form-card" data-aos="flip-right">
              <h2>Contact</h2>
              <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Name" required />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Email" required />

                <label htmlFor="phone">Phone:</label>
                <input type="tel" id="phone" name="phone" placeholder="Phone" required />

                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

                <button type="submit">Submit</button>
              </form>
            </div>
            {/* Use the Popup component */}
            {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessERP;
