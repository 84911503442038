import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const DentalClinicManagementSystem = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="all-products-wrapper">
        <div className="design-container">
          <h1 data-aos="zoom-in">
            Dental Clinic <br /> Management System
          </h1>
          <img src={Images.DCMS} alt="Dental Clinic Management System" className="images" data-aos="zoom-in" />
        </div>

        <div className="new-div">
          <div className="left-content">
            <div className="card" data-aos="zoom-in">
              <h3>About Dental Clinic Management System</h3>
              <p className="explain animated-text">The Dental Clinic Management System is a comprehensive software solution designed to streamline and enhance the operations of dental clinics. It provides a robust set of features for managing users, staff, patients, procedures, invoices, and reports. The system supports multiple PCs and includes additional tools for everyday tasks, ensuring that clinic operations run smoothly and efficiently.</p>
            </div>

            <div className="card" data-aos="zoom-in">
              <h3>Features</h3>
              <div className="card-features">
                <div className="dcms-feature-item animated-text">User Management</div>
                <div className="dcms-feature-item animated-text">Database Management</div>
                <div className="dcms-feature-item animated-text">Staff</div>
                <div className="dcms-feature-item animated-text">Patient</div>
                <div className="dcms-feature-item animated-text">Procedure</div>
                <div className="dcms-feature-item animated-text">Invoice</div>
                <div className="dcms-feature-item animated-text">Reports</div>
                <div className="dcms-feature-item animated-text">Support Multiple PC</div>
              </div>
            </div>
          </div>

          <div className="right-content">
            <div className="form-card" data-aos="flip-right">
              <h2>Contact</h2>
              <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Name" required />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Email" required />

                <label htmlFor="phone">Phone:</label>
                <input type="tel" id="phone" name="phone" placeholder="Phone" required />

                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

                <button type="submit">Submit</button>
              </form>
            </div>
            {/* Use the Popup component */}
            {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default DentalClinicManagementSystem;
