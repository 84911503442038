import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const UserManagement = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="all-products-wrapper">
        <div className="design-container">
          <h1 data-aos="zoom-in">User Management</h1>
          <img src={Images.UM} alt="User Management" className="images" data-aos="zoom-in" />
        </div>

        <div className="new-div">
          <div className="left-content">
            <div className="card" data-aos="zoom-in">
              <h3 className="heading2">About User Management</h3>
              <p className="explain animated-text">User Management is ASP.NET Core (8.0), IdentityCore, C#, EF code first web applications which help you to create, update delete, read, and manage user information very smoothly with cool UI and firster paging, sorting, and searching functionality. Along with each user’s login history.</p>
            </div>

            <div className="card" data-aos="zoom-in">
              <h3 className="heading2">Features</h3>
              <div className="card-features">
                <div className="um-feature-item animated-text">Integrated with AdminLTE Theme, 100% responsive, works well in mobile, tablet, laptop, and desktop devices</div>
                <div className="um-feature-item animated-text">Interactive Dashboard</div>
                <div className="um-feature-item animated-text">Add, Update and Delete User and Role</div>
                <div className="um-feature-item animated-text">User Listing with server-side faster pagination, filtering, and sorting</div>
                <div className="um-feature-item animated-text">Option to send email via SMTP</div>
                <div className="um-feature-item animated-text">Option to send email via SendGrid</div>
                <div className="um-feature-item animated-text">Admin can Setup Page(Screen) and Action mapping</div>
                <div className="um-feature-item animated-text">You can view user login activity</div>
                <div className="um-feature-item animated-text">Users can manage User profiles</div>
                <div className="um-feature-item animated-text">Admin Can Reset User password from the panel</div>
              </div>
            </div>
          </div>

          <div className="right-content">
            <div className="form-card" data-aos="flip-right">
              <h2>Contact</h2>
              <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Name" required />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Email" required />

                <label htmlFor="phone">Phone:</label>
                <input type="tel" id="phone" name="phone" placeholder="Phone" required />

                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

                <button type="submit">Submit</button>
              </form>
            </div>
            {/* Use the Popup component */}
            {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserManagement;
