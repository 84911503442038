import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const BIDashboard = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <div className="bi-wrapper">
      <div className="design-container">
        <h1 data-aos="zoom-in">BI DASHBOARD</h1>
        <img src={Images.biDashboardsImage} alt="BI Dashboard" className="bi-image" data-aos="zoom-in" />
      </div>

      <div className="new-div">
        <div className="left-content">
          <div className="card" data-aos="zoom-in">
            <h2>BI Dashboard</h2>
            <p className="animated-text">In the era of data-driven decision-making, Business Intelligence (BI) dashboards play a vital role in providing actionable insights and analytics. A well-designed BI dashboard can transform complex data into intuitive visualizations, enabling businesses to make informed decisions and drive growth. By leveraging BI tools, organizations can monitor key performance indicators, track trends, and identify opportunities for improvement.</p>
          </div>
          <div className="card" data-aos="zoom-in">
            <h2>Why Choose Us for BI Dashboard Development</h2>
            <p className="animated-text">
              We specialize in creating visually appealing and informative dashboards that make data easy to understand. Our designs focus on presenting key metrics and insights in a clear and concise manner.
              <br />
              <br />
              Our dashboards are tailored to your specific needs, allowing you to customize views, reports, and data sources. This flexibility ensures that you have access to the most relevant information for your business. <br />
              <br />
              We build dashboards that provide real-time data updates, allowing you to stay on top of trends and make timely decisions. This feature is crucial for maintaining a competitive edge in today's fast-paced market. <br />
              <br />
              Our dashboards integrate seamlessly with various data sources and BI tools, ensuring that you can access and analyze data from multiple platforms efficiently.
            </p>
          </div>
        </div>
        <div className="right-content">
          <div className="form-card" data-aos="flip-right">
            <h2>Contact</h2>
            <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" placeholder="Name" required />

              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" placeholder="Email" required />

              <label htmlFor="phone">Phone:</label>
              <input type="tel" id="phone" name="phone" placeholder="Phone" required />

              <label htmlFor="message">Message:</label>
              <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

              <button type="submit">Submit</button>
            </form>
          </div>
          {/* Use the Popup component */}
          {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
        </div>
      </div>
    </div>
  );
};

export default BIDashboard;
