import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const DigitalMarketing = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <div className="digital-wrapper">
      <div className="design-container">
        <h1 data-aos="zoom-in">DIGITAL MARKETING</h1>
        <img src={Images.digitalMarketingImage} alt="Digital Marketing" className="image" data-aos="zoom-in" />
      </div>

      <div className="new-div">
        <div className="left-content">
          <div className="card" data-aos="zoom-in">
            <h2>Digital Marketing</h2>
            <p className="explain animated-text">In the digital age, effective marketing strategies are essential for reaching and engaging your target audience. Digital marketing encompasses various techniques and channels designed to promote your brand, drive traffic, and convert leads into customers. By leveraging digital marketing, businesses can expand their reach and build strong relationships with their audience.</p>
          </div>
          <div className="card" data-aos="zoom-in">
            <h2>Our Services Include</h2>
            <div className="card-features">
              <div className="digital-feature-item animated-text">Social media marketing and management</div>
              <div className="digital-feature-item animated-text">Email marketing campaigns</div>
              <div className="digital-feature-item animated-text">Pay-per-click (PPC) advertising</div>
              <div className="digital-feature-item animated-text">Content creation and strategy</div>
            </div>
          </div>
          <div className="card" data-aos="zoom-in">
            <h2>Why Choose Us for Digital Marketing?</h2>
            <p className="explain animated-text">
              <span className="span-heading">Strategic Planning</span> <br />
              We develop tailored digital marketing strategies that align with your business goals and target audience. Our approach ensures that your marketing efforts are focused and effective. <br />
              <br />
              <span className="span-heading">Multi-Channel Campaigns</span> <br />
              Our digital marketing services include a variety of channels, such as social media, email marketing, PPC advertising, and content marketing. This multi-channel approach helps you reach your audience where they are most active. <br />
              <br />
              <span className="span-heading">Data-Driven Insights</span> <br />
              We use data and analytics to guide our digital marketing efforts, optimizing campaigns based on performance metrics and user behavior. This approach ensures that your marketing strategies are continually refined for maximum impact. <br />
              <br />
              <span className="span-heading">Creative Content & Design</span> <br />
              Our team creates engaging content and eye-catching designs that resonate with your audience and drive conversions. From compelling copy to visually appealing graphics, we focus on delivering content that captures attention and generates results. <br />
            </p>
          </div>
        </div>
        <div className="right-content">
          <div className="form-card" data-aos="flip-right">
            <h2>Contact</h2>
            <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" placeholder="Name" required />

              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" placeholder="Email" required />

              <label htmlFor="phone">Phone:</label>
              <input type="tel" id="phone" name="phone" placeholder="Phone" required />

              <label htmlFor="message">Message:</label>
              <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

              <button type="submit">Submit</button>
            </form>
          </div>
          {/* Use the Popup component */}
          {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
        </div>
      </div>
    </div>
  );
};

export default DigitalMarketing;
