import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const ParkingAdministrationSystem = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="all-products-wrapper">
        <div className="design-container">
          <h1 data-aos="zoom-in">Parking Administration System</h1>
          <img src={Images.ParkingMS} alt="Parking Administration System" className="images" data-aos="zoom-in" />
        </div>

        <div className="new-div">
          <div className="left-content">
            <div className="card" data-aos="zoom-in">
              <h3>About Parking Administration System</h3>
              <p className="explain animated-text">The Parking Administration System is a comprehensive software solution designed to streamline the management of parking facilities. It provides a robust set of features for efficiently managing users, vehicles, parking spaces, and financial transactions. This system is ideal for parking lots, garages, and multi-level parking facilities, offering a user-friendly interface and extensive reporting capabilities.</p>
            </div>

            <div className="card" data-aos="zoom-in">
              <h3>Features</h3>
              <div className="card-features">
                <div className="parkingms-feature-item animated-text">Users Management</div>
                <div className="parkingms-feature-item animated-text">System access privileges 1.- Administrator 2.- Employee</div>
                <div className="parkingms-feature-item animated-text">Register Car</div>
                <div className="parkingms-feature-item animated-text">Rate cost adjustments, parking data</div>
                <div className="parkingms-feature-item animated-text">Manage availability of parking spaces</div>
                <div className="parkingms-feature-item animated-text">Register Customer</div>
                <div className="parkingms-feature-item animated-text">Reports of the day</div>
                <div className="parkingms-feature-item animated-text">Reports between date range</div>
                <div className="parkingms-feature-item animated-text">Car Details</div>
                <div className="parkingms-feature-item animated-text">Print Reports</div>
                <div className="parkingms-feature-item animated-text">Receipt Printing</div>
                <div className="parkingms-feature-item animated-text">Very nice and intuitive User Interface Design</div>
              </div>
            </div>
          </div>

          <div className="right-content">
            <div className="form-card" data-aos="flip-right">
              <h2>Contact</h2>
              <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Name" required />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Email" required />

                <label htmlFor="phone">Phone:</label>
                <input type="tel" id="phone" name="phone" placeholder="Phone" required />

                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

                <button type="submit">Submit</button>
              </form>
            </div>
            {/* Use the Popup component */}
            {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default ParkingAdministrationSystem;
