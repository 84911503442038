import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const AdvancPOSSystemWithStockManager = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="all-products-wrapper">
        <div className="design-container">
          <h1 data-aos="zoom-in">
            Advance POS System <br /> with Stock Manager
          </h1>
          <img src={Images.posStock} alt="Advance POS System with Stock Manager" className="images" data-aos="zoom-in" />
        </div>

        <div className="new-div">
          <div className="left-content">
            <div className="card" data-aos="zoom-in">
              <h2 className="heading2">About Advance POS System with Stock Manager</h2>
              <p className="explain animated-text">
                Advance POS System with Stock Manager (APOS) is a multi-functional complete web application developed using ASP.NET Core and EF Core code first technology. <br />
                <br />
                This web application enables you to manage all kinds of retail shops and small businesses through proper monitoring and a dynamic reporting system. APOS is a .NET Core/jQuery-based web application that allows you to manage your sales and inventory from anywhere. It has a built-in Invoice and Inventory System with taxes and discounts. These will be really helpful to apply taxes and discounts automatically and the ability to generate invoices. Update your stock information, make
                purchases and view sales data from anywhere whether in the office, at home, in the warehouse, or on the go. All you need to access this is a device with an internet connection.
              </p>
            </div>

            <div className="card" data-aos="zoom-in">
              <h2 className="heading2">Core Features</h2>
              <p className="explain animated-text">
                <span className="span-heading"> Dashboard:-</span>
                <br />
                With Overview & Best Seller Pie Chart, Latest Eight (Invoice Today, Invoice Monthly, Sales Today, Sales Monthly, Customers & Suppliers) items summary.
                <br />
                <br />
                <span className="span-heading">Invoice Management:- </span>
                <br />
                Easily track invoice that is in the register and manage additions and removals from the register. Reporting is also available for this to easily track shortages and overages.
                <br />
                <br />
                <span className="span-heading">Inventory Management:- </span>
                <br />
                Do whole or partial inventory counts and stay up to date with history and proper reporting.
                <br />
                <br />
                <span className="span-heading"> Item Variations:-</span>
                <br />
                Create different sizes, categories, or any attributes you define for your items. You can track inventory down to the variation and even have different prices per variation.
                <br />
                <br />
                <span className="span-heading"> Item modifiers:-</span>
                <br />
                Modify inventory items, and customer invoices easily. Choose add-ons to dishes or how they’re prepared in one click.
                <br />
                <br />
                <span className="span-heading"> Reports:-</span>
                <br />
                Get comprehensive insights into your business with 10+ detailed reports designed for you. Overview & item stock, product quantity and damage item, general profit and/or loss report, sales, purchases, customer, suppliers, and staff user reports.
                <br />
                <br />
                <span className="span-heading"> Currency Exchange:-</span>
                <br />
                Easily accept any Currency Denomination While making a Sale or Return.
                <br />
                <br />
                <span className="span-heading">Barcode Label Printing:- </span>
                <br />
                Easily create barcode labels for your products when they arrive in your store or on-demand.
                <br />
                <br />
                <span className="span-heading"> Payment Type:-</span>
                <br />
                Credit Card, EBT/EBT Processing, Cash, Check, Gift cards you can define your own custom payment methods.
                <br />
                <br />
                <span className="span-heading"> Serial Numbers:-</span>
                <br />
                You can now manage items with serial numbers, relation with barcode and optionally you can also assign different prices depending on the serial number.
                <br />
                <br />
                <span className="span-heading">Custom Receipts:- </span>
                <br />
                Easily customize your receipts to match what you need for your business. There are 5 different configuration options for making your receipts how you want them.
                <br />
                <br />
                <span className="span-heading"> Inventory history:-</span>
                <br />
                Gain insights into the flow of your inventory by viewing the adjustment log.
                <br />
                <br />
                <span className="span-heading">Admin LTE Theme:- </span>
                <br />
                Use the excellent and responsive theme in a low ambient light environment to reduce eyestrain. It provides a range of responsive, reusable, and commonly used components.
                <br />
                <br />
                <span className="span-heading">People:- </span>
                <br />
                Manager, staff users, billers (selling companies), customers, and suppliers.
                <br />
                <br />
                <span className="span-heading">Settings:- </span>
                <br />
                Easily manage the app with settings for logo, categories, variants, currencies, custom page access, and identity option.
                <br />
                <br />
                <span className="span-heading"> Much more:-</span>
                <br />
                User login history, user management, email configuration, nice UI.
              </p>
            </div>
          </div>

          <div className="right-content">
            <div className="form-card" data-aos="flip-right">
              <h2>Contact</h2>
              <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Name" required />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Email" required />

                <label htmlFor="phone">Phone:</label>
                <input type="tel" id="phone" name="phone" placeholder="Phone" required />

                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

                <button type="submit">Submit</button>
              </form>
            </div>
            {/* Use the Popup component */}
            {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdvancPOSSystemWithStockManager;
