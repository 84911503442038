import React from "react";
import "./style.css";
import Images from "../Images/Images";
import { Link } from "react-router-dom";

const Blogs = () => {
  return (
    <div className="blog-wrapper">
      <div className="container-xxl py-5 bg-primary hero-about">
        <div className="container my-5 py-5 px-lg-5">
          <div className="row g-5 py-5">
            <div className="col-12 text-center">
              <h1 className="text-white blog-line">
                <div data-aos="flip-up">BLOGS</div>
              </h1>
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item text-white" aria-current="page">
                  Discover More About Our Insights
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-text">
        <div>
          <div>
            <h3>Insights & Ideas for Transformation</h3>
            <p>Know more about what’s talked about in technology as our experts dissect the most pertinent information in the digital world.</p>
          </div>
        </div>
      </div>

      <div className="card-container">
        <div className="blogs-card" data-aos="zoom-in">
          <img src={Images.website} alt="BlogImage" />
          <div className="card-header">
            <span className="small-text">Website Development</span>
            <span className="date">31 May 2024</span>
          </div>
          <h3>The Future of Website Development: Trends, Tools, and Best Practices</h3>
          <Link to="/blog/website-development" className="learn-more">
            Learn More <i className="fas fa-arrow-right"></i>
          </Link>
        </div>

        <div className="blogs-card" data-aos="zoom-in">
          <img src={Images.app} alt="BlogImage" />
          <div className="card-header">
            <span className="small-text">Apps Development</span>
            <span className="date">31 May 2024</span>
          </div>
          <h3>The Ultimate Guide to App Development: Trends, Lifecycle, and Best Practices</h3>
          <Link to="/blog/apps-development" className="learn-more">
            Learn More <i className="fas fa-arrow-right"></i>
          </Link>
        </div>

        <div className="blogs-card" data-aos="zoom-in">
          <img src={Images.ecommerce} alt="BlogImage" />
          <div className="card-header">
            <span className="small-text">E-Commerce Development</span>
            <span className="date">31 May 2024</span>
          </div>
          <h3>The Future of E-Commerce: Emerging Trends and Essential Tools for Modern Businesses</h3>
          <Link to="/blog/eCommerce-evelopment" className="learn-more">
            Learn More <i className="fas fa-arrow-right"></i>
          </Link>
        </div>

        <div className="blogs-card" data-aos="zoom-in">
          <img src={Images.software} alt="BlogImage" />
          <div className="card-header">
            <span className="small-text">Software Customization</span>
            <span className="date">31 May 2024</span>
          </div>
          <h3>Mastering Software Customization: Tailoring Solutions to Fit Your Unique Business Needs</h3>
          <Link to="/blog/software-customization" className="learn-more">
            Learn More <i className="fas fa-arrow-right"></i>
          </Link>
        </div>

        <div className="blogs-card" data-aos="zoom-in">
          <img src={Images.ai} alt="BlogImage" />
          <div className="card-header">
            <span className="small-text">Artificial Intelligence</span>
            <span className="date">31 May 2024</span>
          </div>
          <h3>The Impact of Artificial Intelligence: Key Applications and Emerging Trends</h3>
          <Link to="/blog/artificial-intelligence" className="learn-more">
            Learn More <i className="fas fa-arrow-right"></i>
          </Link>
        </div>

        <div className="blogs-card" data-aos="zoom-in">
          <img src={Images.machine} alt="BlogImage" />
          <div className="card-header">
            <span className="small-text">Machine Learning</span>
            <span className="date">31 May 2024</span>
          </div>
          <h3>Exploring the World of Machine Learning: Essential Techniques and Practical Applications</h3>
          <Link to="/blog/machine-learning" className="learn-more">
            Learn More <i className="fas fa-arrow-right"></i>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
