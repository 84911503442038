import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const AppDevelopment = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <div className="apps-wrapper">
      <div className="design-container">
        <h1 data-aos="zoom-in">APPS DEVELOPMENT</h1>
        <img src={Images.appsDevelopmentImage} alt="Apps Development" className="image" data-aos="zoom-in" />
      </div>

      <div className="new-div">
        <div className="left-content">
          <div className="card" data-aos="zoom-in">
            <h2> Apps Development </h2>
            <p className="explain animated-text">
              In today's tech-driven world, mobile and web applications have become crucial for businesses to engage with their audience effectively. An app is not just a tool but a gateway to interact with your customers on a personal level. A well-developed app enhances user experience, drives engagement, and boosts overall brand loyalty. By leveraging the latest technologies and trends, businesses can maximize their reach and create a significant impact in their respective industries.
            </p>
          </div>
          <div className="card" data-aos="zoom-in">
            <h2>Our Expertise Includes</h2>
            <div className="card-features">
              <div className="app-feature-item animated-text">Native and hybrid app development</div>
              <div className="app-feature-item animated-text">Interactive and engaging UI/UX design</div>
              <div className="app-feature-item animated-text">Integration with third-party APIs and services</div>
              <div className="app-feature-item animated-text">Continuous support and maintenance</div>
            </div>
          </div>
          <div className="card" data-aos="zoom-in">
            <h2>Why Choose Us for App Development?</h2>
            <p className="explain animated-text">
              <span className="span-heading">Innovative Solutions</span> <br />
              Our team of skilled developers brings creativity and innovation to every project. We focus on delivering unique and functional apps tailored to meet your business goals and user needs. <br />
              <br />
              <span className="span-heading">User-Centric Design</span> <br />
              We prioritize user experience and interface design to ensure that your app is intuitive and easy to navigate. Our designs are crafted to engage users and provide a seamless experience across various devices. <br />
              <br />
              <span className="span-heading">Performance & Scalability</span> <br />
              We build apps with performance in mind, ensuring smooth functionality and fast load times. Our solutions are scalable, allowing your app to grow and evolve with your business. <br />
              <br />
              <span className="span-heading">Cross-Platform Development</span> <br />
              Whether it's iOS, Android, or web, we develop cross-platform apps that provide a consistent experience across all devices. This approach helps you reach a broader audience and optimize your app’s performance. <br />
            </p>
          </div>
        </div>
        <div className="right-content">
          <div className="form-card" data-aos="flip-right">
            <h2>Contact</h2>
            <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" placeholder="Name" required />

              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" placeholder="Email" required />

              <label htmlFor="phone">Phone:</label>
              <input type="tel" id="phone" name="phone" placeholder="Phone" required />

              <label htmlFor="message">Message:</label>
              <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

              <button type="submit">Submit</button>
            </form>
          </div>
          {/* Use the Popup component */}
          {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
        </div>
      </div>
    </div>
  );
};

export default AppDevelopment;
