import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const LaundryBoxPOS = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="all-products-wrapper">
        <div className="design-container">
          <h1 data-aos="zoom-in">
            Laundry Box POS <br /> and Order Management System
          </h1>
          <img src={Images.LBAOMS} alt="Laundry Box POS and Order Management System" className="images" data-aos="zoom-in" />
        </div>

        <div className="new-div">
          <div className="left-content">
            <div className="card" data-aos="zoom-in">
              <h3>About Laundry Box POS and Order Management System </h3>
              <p className="explain animated-text">A Laundry Box Point of Sale (POS) and Order Management System is a specialized software solution designed to streamline and manage the operations of a laundry service business. This system combines features of a traditional POS system with order management capabilities tailored to the specific needs of a laundry service.</p>
            </div>

            <div className="card" data-aos="zoom-in">
              <h3 className="heading2">Features</h3>
              <div className="card-features">
                <div className="lbaoms-feature-item animated-text">Register New User</div>
                <div className="lbaoms-feature-item animated-text">Reset Password</div>
                <div className="lbaoms-feature-item animated-text">Admin Dashboard</div>
                <div className="lbaoms-feature-item animated-text">Recent Registered User</div>
                <div className="lbaoms-feature-item animated-text">Identity Setting</div>
                <div className="lbaoms-feature-item animated-text">Login History</div>
                <div className="lbaoms-feature-item animated-text">Dedicated POS Module</div>
                <div className="lbaoms-feature-item animated-text">Highly Enhanced Dashboard</div>
                <div className="lbaoms-feature-item animated-text">Order Management</div>
                <div className="lbaoms-feature-item animated-text">Order Status Screen</div>
                <div className="lbaoms-feature-item animated-text">Customer Management</div>
                <div className="lbaoms-feature-item animated-text">Expense Management</div>
                <div className="lbaoms-feature-item animated-text">Service Management</div>
                <div className="lbaoms-feature-item animated-text">Translation Module</div>
                <div className="lbaoms-feature-item animated-text">Multiple Business Reports</div>
                <div className="lbaoms-feature-item animated-text">Branch Module</div>
                <div className="lbaoms-feature-item animated-text">Branch Wise Reporting</div>
                <div className="lbaoms-feature-item animated-text">Daily Report</div>
                <div className="lbaoms-feature-item animated-text">Order Report</div>
                <div className="lbaoms-feature-item animated-text">Sales Report</div>
                <div className="lbaoms-feature-item animated-text">Payment Transaction History</div>
                <div className="lbaoms-feature-item animated-text">Order Status Pie Chart</div>
                <div className="lbaoms-feature-item animated-text">Edit Options for Order Item</div>
                <div className="lbaoms-feature-item animated-text">Thermal Printer Option</div>
                <div className="lbaoms-feature-item animated-text">Built with ASP.NET</div>
                <div className="lbaoms-feature-item animated-text">ASP.NET Identity Authentication</div>
                <div className="lbaoms-feature-item animated-text">Audit Logs</div>
                <div className="lbaoms-feature-item animated-text">Multi-Language Support</div>
                <div className="lbaoms-feature-item animated-text">Dynamic Role Management</div>
                <div className="lbaoms-feature-item animated-text">Responsive Design</div>
              </div>
            </div>
          </div>

          <div className="right-content">
            <div className="form-card" data-aos="flip-right">
              <h2>Contact</h2>
              <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Name" required />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Email" required />

                <label htmlFor="phone">Phone:</label>
                <input type="tel" id="phone" name="phone" placeholder="Phone" required />

                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

                <button type="submit">Submit</button>
              </form>
            </div>
            {/* Use the Popup component */}
            {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default LaundryBoxPOS;
