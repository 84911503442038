import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const BarcodeGenerator = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="all-products-wrapper">
        <div className="design-container">
          <h1 data-aos="zoom-in">Barcode Generator</h1>
          <img src={Images.BarcodeG} alt="Barcode Generator" className="images" data-aos="zoom-in" />
        </div>

        <div className="new-div">
          <div className="left-content">
            <div className="card" data-aos="zoom-in">
              <h3 className="heading2">About Barcode Generator</h3>
              <p className="explain animated-text">The Barcode Generator is a versatile and easy-to-use tool designed to create various types of barcodes for multiple applications. Whether you need barcodes for product labeling, inventory management, retail, or any other purpose, this tool simplifies the process by allowing users to generate high-quality barcodes in seconds. It supports a wide range of barcode formats, making it suitable for different industries and use cases.</p>
            </div>

            <div className="card" data-aos="zoom-in">
              <h3 className="heading2">Features</h3>
              <div className="card-features">
                <div className="barcodeg-feature-item animated-text">Barcode generator type EAN13</div>
                <div className="barcodeg-feature-item animated-text">QR Code Generator</div>
                <div className="barcodeg-feature-item animated-text">Save the generated code as a PNG image</div>
                <div className="barcodeg-feature-item animated-text">Record the code generated in the database</div>
                <div className="barcodeg-feature-item animated-text">Duplicate code control</div>
                <div className="barcodeg-feature-item animated-text">Print the generated code</div>
              </div>
            </div>
          </div>

          <div className="right-content">
            <div className="form-card" data-aos="flip-right">
              <h2>Contact</h2>
              <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Name" required />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Email" required />

                <label htmlFor="phone">Phone:</label>
                <input type="tel" id="phone" name="phone" placeholder="Phone" required />

                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

                <button type="submit">Submit</button>
              </form>
            </div>
            {/* Use the Popup component */}
            {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default BarcodeGenerator;
