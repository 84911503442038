import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const EmployeeManagementHRM = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="all-products-wrapper">
        <div className="design-container">
          <h1 data-aos="zoom-in">Employee Management - HRM</h1>
          <img src={Images.EMS} alt="Employee Management - HRM" className="images" data-aos="zoom-in" />
        </div>

        <div className="new-div">
          <div className="left-content">
            <div className="card" data-aos="zoom-in">
              <h3 className="heading2">About Employee Management - HRM</h3>
              <p className="explain animated-text">The Employee Management - HRM (Human Resource Management) system is designed to streamline and automate various HR functions within an organization. It offers a comprehensive suite of features to manage employee information, track work hours, handle payroll, and generate detailed reports. With its intuitive user interface and robust functionality, this system helps HR departments efficiently manage and support their workforce.</p>
            </div>

            <div className="card" data-aos="zoom-in">
              <h3 className="heading2">Features</h3>
              <div className="card-features">
                <div className="ems-feature-item animated-text">User registration with their respective access privileges</div>
                <div className="ems-feature-item animated-text">Employee registration</div>
                <div className="ems-feature-item animated-text">Registration of position and salaries</div>
                <div className="ems-feature-item animated-text">Control of time of entry and exit of employees</div>
                <div className="ems-feature-item animated-text">Payments to employees</div>
                <div className="ems-feature-item animated-text">Impression of payment receipt</div>
                <div className="ems-feature-item animated-text">Payment report</div>
                <div className="ems-feature-item animated-text">Report printing</div>
              </div>
            </div>
          </div>

          <div className="right-content">
            <div className="form-card" data-aos="flip-right">
              <h2>Contact</h2>
              <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Name" required />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Email" required />

                <label htmlFor="phone">Phone:</label>
                <input type="tel" id="phone" name="phone" placeholder="Phone" required />

                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

                <button type="submit">Submit</button>
              </form>
            </div>
            {/* Use the Popup component */}
            {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeManagementHRM;
