import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import Footer from "./components/Footer/Footer";
import About from "./components/About/About";
import SocialIcons from "./components/SocialIcons/SocialIcons";
// import Loader from "./components/Loader/Loader";
import BackToTopAndWhatsApp from "./components/BackToTopAndWhatsApp/BackToTopAndWhatsApp";
import Services from "./components/Services/Services";
import "./App.css";
import Clients from "./components/Clients/Clients";
import Products from "./components/Products/Products";
import Blogs from "./components/Blogs/Blogs";
import Contact from "./components/Contact/Contact";

import AppDevelopment from "./components/AllServices/AppDevelopment/AppDevelopment";
import BiDashboard from "./components/AllServices/BiDashboard/BiDashboard";
import DigitalMarketing from "./components/AllServices/DigitalMarketing/DigitalMarketing";
import SeoOptimization from "./components/AllServices/SeoOptimization/SeoOptimization";
import SoftwareDevelopment from "./components/AllServices/SoftwareDevelopment/SoftwareDevelopment";
import WebsiteDevelopment from "./components/AllServices/WebsiteDevelopment/WebsiteDevelopment";

import BlogAppDevelopment from "./components/AllBlogs/BlogAppDevelopment/BlogAppDevelopment";
import BlogArtificialIntelligence from "./components/AllBlogs/BlogArtificialIntelligence/BlogArtificialIntelligence";
import BlogEcommerceDevelopment from "./components/AllBlogs/BlogEcommerceDevelopment/BlogEcommerceDevelopment";
import BlogMachineLearning from "./components/AllBlogs/BlogMachineLearning/BlogMachineLearning";
import BlogSoftwareDevelopment from "./components/AllBlogs/BlogSoftwareDevelopment/BlogSoftwareDevelopment";
import BlogWebsiteDevelopment from "./components/AllBlogs/BlogWebsiteDevelopment/BlogWebsiteDevelopment";

import AdvancPOSSystemWithStockManager from "./components/AllProducts/AdvancPOSSystemWithStockManager/AdvancPOSSystemWithStockManager";
import DentalClinicManagementSystem from "./components/AllProducts/DentalClinicManagementSystem/DentalClinicManagementSystem";
import BusinessERPSolution from "./components/AllProducts/BusinessERPSolution/BusinessERPSolution";
import AssetManagementSystem from "./components/AllProducts/AssetManagementSystem/AssetManagementSystem";
import HospitalManagementSystem from "./components/AllProducts/HospitalManagementSystem/HospitalManagementSystem";
import AdvancedFilesAndUsersManagement from "./components/AllProducts/AdvancedFilesAndUsersManagement/AdvancedFilesAndUsersManagement";
import InventoryManagementAndUserManagement from "./components/AllProducts/InventoryManagementAndUserManagement/InventoryManagementAndUserManagement";
import HelpDeskSupportSystem from "./components/AllProducts/HelpDeskSupportSystem/HelpDeskSupportSystem";
import LeaveManagementSystem from "./components/AllProducts/LeaveManagementSystem/LeaveManagementSystem";
import UserManagementAndRealtimeChatApp from "./components/AllProducts/UserManagementAndRealtimeChatApp/UserManagementAndRealtimeChatApp";
import LaundryBoxPOSAndOrderManagementSystem from "./components/AllProducts/LaundryBoxPOSAndOrderManagementSystem/LaundryBoxPOSAndOrderManagementSystem";
import LibraryManagementSystem from "./components/AllProducts/LibraryManagementSystem/LibraryManagementSystem";
import UserManagement from "./components/AllProducts/UserManagement/UserManagement";
import WhatsAppAutoReplyBot from "./components/AllProducts/WhatsAppAutoReplyBot/WhatsAppAutoReplyBot";
import BarcodeGenerator from "./components/AllProducts/BarcodeGenerator/BarcodeGenerator";
import PointOfSalePOSForRestaurants from "./components/AllProducts/PointOfSalePOSForRestaurants/PointOfSalePOSForRestaurants";
import ProjectManagementSystem from "./components/AllProducts/ProjectManagementSystem/ProjectManagementSystem";
import PharmacyManagementSystem from "./components/AllProducts/PharmacyManagementSystem/PharmacyManagementSystem";
import HumanResourceManagement from "./components/AllProducts/HumanResourceManagement/HumanResourceManagement";
import ParkingAdministrationSystem from "./components/AllProducts/ParkingAdministrationSystem/ParkingAdministrationSystem";
import EmployeeManagementHRM from "./components/AllProducts/EmployeeManagementHRM/EmployeeManagementHRM";
import MultiVendorFeedbackSurveySystem from "./components/AllProducts/MultiVendorFeedbackSurveySystem/MultiVendorFeedbackSurveySystem";
import PointOfSalePOSPharmacy from "./components/AllProducts/PointOfSalePOSPharmacy/PointOfSalePOSPharmacy";
import HotelRoomSystem from "./components/AllProducts/HotelRoomSystem/HotelRoomSystem";
import SchoolCollegeUniversityManagementERP from "./components/AllProducts/SchoolCollegeUniversityManagementERP/SchoolCollegeUniversityManagementERP";

import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

gsap.registerPlugin(ScrollTrigger);

function App() {
  // AOS and GSAP initialization
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 1500,
      offset: 200,
      easing: "ease-in-out",
    });

    // Initialize GSAP animation for scrolling
    gsap.to(".animated-text", {
      scrollTrigger: {
        trigger: ".animated-text",
        start: "top center",
        end: "bottom center",
        scrub: 1,
        toggleActions: "play none none reverse",
      },
      color: "black",
      duration: 1,
    });
  }, []);

  return (
    <Router>
      <div>
        <ScrollToTop />
        <Navbar />
        <SocialIcons />
        {/* <Loader /> */}
        <BackToTopAndWhatsApp />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/products" element={<Products />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/contact" element={<Contact />} />

          <Route path="/services/apps-development" element={<AppDevelopment />} />
          <Route path="/services/bi-dashboard" element={<BiDashboard />} />
          <Route path="/services/digital-marketing" element={<DigitalMarketing />} />
          <Route path="/services/seo-optimization" element={<SeoOptimization />} />
          <Route path="/services/software-development" element={<SoftwareDevelopment />} />
          <Route path="/services/website-development" element={<WebsiteDevelopment />} />

          <Route path="/blog/apps-development" element={<BlogAppDevelopment />} />
          <Route path="/blog/artificial-intelligence" element={<BlogArtificialIntelligence />} />
          <Route path="/blog/eCommerce-evelopment" element={<BlogEcommerceDevelopment />} />
          <Route path="/blog/machine-learning" element={<BlogMachineLearning />} />
          <Route path="/blog/software-customization" element={<BlogSoftwareDevelopment />} />
          <Route path="/blog/website-development" element={<BlogWebsiteDevelopment />} />

          <Route path="/products/dental-clinic-management-system" element={<DentalClinicManagementSystem />} />
          <Route path="/products/business-erp-solution" element={<BusinessERPSolution />} />
          <Route path="/products/asset-management-system" element={<AssetManagementSystem />} />
          <Route path="/products/hospital-management-system" element={<HospitalManagementSystem />} />
          <Route path="/products/advance-pos-system-with-stock-manager" element={<AdvancPOSSystemWithStockManager />} />
          <Route path="/products/advanced-files-&-users-management" element={<AdvancedFilesAndUsersManagement />} />
          <Route path="/products/inventory-management-and-user-management" element={<InventoryManagementAndUserManagement />} />
          <Route path="/products/helpdesk-support-system" element={<HelpDeskSupportSystem />} />
          <Route path="/products/leave-management-system" element={<LeaveManagementSystem />} />
          <Route path="/products/user-management-and-realtime-chat-app" element={<UserManagementAndRealtimeChatApp />} />
          <Route path="/products/laundry-box-pos-and-order-management-system" element={<LaundryBoxPOSAndOrderManagementSystem />} />
          <Route path="/products/library-management-system" element={<LibraryManagementSystem />} />
          <Route path="/products/user-management" element={<UserManagement />} />
          <Route path="/products/whatsapp-auto-reply-bot" element={<WhatsAppAutoReplyBot />} />
          <Route path="/products/barcode-generator" element={<BarcodeGenerator />} />
          <Route path="/products/point-of-sale-pos-for-restaurants" element={<PointOfSalePOSForRestaurants />} />
          <Route path="/products/project-management-system" element={<ProjectManagementSystem />} />
          <Route path="/products/pharmacy-management-system" element={<PharmacyManagementSystem />} />
          <Route path="/products/human-resource-management" element={<HumanResourceManagement />} />
          <Route path="/products/parking-administration-system" element={<ParkingAdministrationSystem />} />
          <Route path="/products/employee-management-hrm" element={<EmployeeManagementHRM />} />
          <Route path="/products/multi-vendor-feedback-survey-system" element={<MultiVendorFeedbackSurveySystem />} />
          <Route path="/products/point-of-sale(pos)-pharmacy" element={<PointOfSalePOSPharmacy />} />
          <Route path="/products/hotel-room-system" element={<HotelRoomSystem />} />
          <Route path="/products/school-college-university-management-erp" element={<SchoolCollegeUniversityManagementERP />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
