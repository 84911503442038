import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const WebsiteDevelopment = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <div className="website-wrapper">
      <div className="design-container">
        <h1 data-aos="zoom-in">WEBSITE DEVELOPMENT</h1>
        <img src={Images.websiteDevelopmentImage} alt="Website Development" className="image" data-aos="zoom-in" />
      </div>

      <div className="new-div">
        <div className="left-content">
          <div className="card" data-aos="zoom-in">
            <h2>Website Development</h2>
            <p className="animated-text">
              As we are living in the era of the digital world, the website is the main core of any business. A website is one of the prominent features of digital marketing, not only a website but a beautiful and well-designed website that enhances features of any business. To increase the reach of business and profit ratio it is a must to digitalize the business to gain maximum reach to customers and acquire your space in the cut-throat competitive market. The Internet has unlimited power and
              reaches the target customers for business and services provided by any website development company. The best way to utilize the power of the internet is to go digital and spread the business like butter on a slice. <br />
              <br />
              Mind Marq, being a top-notch website developing company in Mumbai is providing all kinds of website development services that too at an effective cost. We have a creative, dedicated, and experienced team with us to complete all operations regarding websites. Mind Marq is a well progressive Web Development Company in Mumbai, which is working across the globe.
            </p>
          </div>
          <div className="card" data-aos="zoom-in">
            <h2>Why Are We the Best Web Development Company</h2>
            <p className="animated-text">
              <span className="span-heading">Creativity </span> <br />
              Mind Marq has proven past experience in terms of creativity and quality. Mind Marq provides unique web designs and themes for their clients as per their requirements and budget to make every website stand out from the crowd. Our experienced web development team works wholeheartedly to meet your business needs. <br />
              <br />
              <span className="span-heading">Responsive</span> <br />
              Most internet users are mobile consumers; according to a recent survey, about 70 percent of people across the world use a mobile device for visiting sites on the internet. Our intelligent user interface develops the website to be very responsive on both mobile and desktop platforms. <br />
              <br />
              <span className="span-heading">Effective</span> <br />
              Mind Marq can make your website the most effective one so that you can generate a larger number of sales as a gem. Our web developer team develops a website to achieve more sales; moreover, it also converts your website visitors into customers. <br />
              <br />
              <span className="span-heading">Customized</span> <br />
              For market development, we create a solid customized site with maximum options, which is very easy to handle. Our affordable web designing team will spend most of their time to make your site more productive and beautiful.
            </p>
          </div>
          <div className="card" data-aos="zoom-in">
            <h2 className="heading2">Mind Marq Star Picks</h2>
            <p className="animated-text">
              Highly functional interactive websites with a user-friendly interface & smooth navigation. <br />
              <br />
              Mobile optimized responsive website development. <br />
              <br />
              Complex HTML5 Animations, Gaming & custom interactivity according to your devices. <br />
              <br />
              Feature-rich client applications with cross-browser, cross-platform, cross-device functionality. <br />
              <br />A well-developed website has unlimited potential to attain the maximum reach of the business. Mind Marq is a leading web development company in Mumbai that understands each and every micro information about the website and business to provide excellent services. The crucial part of designing any website is understanding the main motive of the website and its user interface that acts like a magnet to the customers. Customer satisfaction is the backbone of our organization, and
              we continuously work hard to maximize it and provide a rich experience with one of the best website development companies - Mind Marq.
            </p>
          </div>
        </div>
        <div className="right-content">
          <div className="form-card" data-aos="flip-right">
            <h2>Contact</h2>
            <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" placeholder="Name" required />

              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" placeholder="Email" required />

              <label htmlFor="phone">Phone:</label>
              <input type="tel" id="phone" name="phone" placeholder="Phone" required />

              <label htmlFor="message">Message:</label>
              <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

              <button type="submit">Submit</button>
            </form>
          </div>
          {/* Use the Popup component */}
          {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
        </div>
      </div>
    </div>
  );
};

export default WebsiteDevelopment;
