import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const MultiVendorFeedbackSurveySystem = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="all-products-wrapper">
        <div className="design-container">
          <h1 data-aos="zoom-in">
            Multi-vendor Feedback <br /> Survey System
          </h1>
          <img src={Images.MVFS} alt="Multi-vendor Feedback Survey System" className="images" data-aos="zoom-in" />
        </div>

        <div className="new-div">
          <div className="left-content">
            <div className="card" data-aos="zoom-in">
              <h3>About Multi-vendor Feedback Survey System</h3>
              <p className="explain animated-text">
                In this product you have an admin panel from where you can create/manage survey group, create/manage survey and many more. This product provides various type of survey questions such as multiple choice, single choice, text entry questions etc. You can create survey in any form, it is very flexible. <br />
                <br />
                This product also has a report section where you can view survey responses.
              </p>
            </div>

            <div className="card" data-aos="zoom-in">
              <h3>Features</h3>
              <div className="card-features">
                <div className="mvfs-feature-item animated-text">It contains admin and user(Vendor) section</div>
                <div className="mvfs-feature-item animated-text">Admin can control vendor user rights</div>
                <div className="mvfs-feature-item animated-text">Sales and Customer Management</div>
                <div className="mvfs-feature-item animated-text">Create/Manage Survey Group</div>
                <div className="mvfs-feature-item animated-text">Create/Manage Survey</div>
                <div className="mvfs-feature-item animated-text">Survey Reports</div>
                <div className="mvfs-feature-item animated-text">Email Template</div>
                <div className="mvfs-feature-item animated-text">Multiple Vendors</div>
                <div className="mvfs-feature-item animated-text">Perform Survey Link Generation</div>
                <div className="mvfs-feature-item animated-text">Very Effective in hotel business</div>
              </div>
            </div>
          </div>

          <div className="right-content">
            <div className="form-card" data-aos="flip-right">
              <h2>Contact</h2>
              <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Name" required />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Email" required />

                <label htmlFor="phone">Phone:</label>
                <input type="tel" id="phone" name="phone" placeholder="Phone" required />

                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

                <button type="submit">Submit</button>
              </form>
            </div>
            {/* Use the Popup component */}
            {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default MultiVendorFeedbackSurveySystem;
