import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const SchoolCollegeUniversityManagementERP = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="all-products-wrapper">
        <div className="design-container">
          <h1 data-aos="zoom-in">
            School College <br /> University Management ERP
          </h1>
          <img src={Images.scums} alt="Customized Billing Software" className="images" data-aos="zoom-in" />
        </div>

        <div className="new-div">
          <div className="left-content">
            <div className="card" data-aos="zoom-in">
              <h2 className="heading2">About School College University Management ERP</h2>
              <p className="explain animated-text">
                School/College/University Management ERP (Enterprise Resource Planning) is a complete web application and collection of programs designed to assist schools in administering their executive responsibilities on a day-to-day basis. Also, it operates your school with much more advantages than a traditional school. This school management system empowers schools to keep track of their daily operations while centralizing their resources and information. This managing system helps to
                reduce the pressure of managing a large amount of data from the school. <br />
                <br />
                It includes the user’s full journey to every school or educational institution from student admission to student leaving, from fee collection to exam results. More than 10+ major modules with 7 inbuilt users (Super Admin, Admin, Accountant, Teacher, Librarian, Parent, and Student) panel. Moreover, it has a robust reporting system for all modules.
              </p>
            </div>

            <div className="card" data-aos="zoom-in">
              <h2 className="heading2">Core Features</h2>
              <p className="explain animated-text">
                <span className="span-heading">Student Payment:- </span> <br />
                Superfast invoice generation and print invoices with multiple options will definitely add another dimension to your business. Also, you can instantly share invoices through email instantly.
                <br />
                <br />
                <span className="span-heading">Attendance:- </span> <br />
                In this school management ERP, super admin, admin, and teacher can take students’ attendance to track academic progress.
                <br />
                <br />
                <span className="span-heading">Class routine:- </span> <br />
                The class routine is another part of academic terms in school management ERP. The super admin and admin create the class routine for different classes. In this panel, class routine helps to complete regular academic activities.
                <br />
                <br />
                <span className="span-heading">Subject:- </span>
                <br />
                In this school management, the subject is a part of academic activities. Students, teachers, and parents have permission to overview subjects. Only super admin and admin can add different subjects for different classes.
                <br />
                <br />
                <span className="span-heading">Syllabus:- </span> <br />
                The syllabus is another essential part of academic activities in school management ERP. The syllabus supports other educational activities, such as class routine, subject, and department. In this panel, the super admin, admin, and teacher have permission to create the syllabus for the students, and only parents and students have permission to view the syllabus.
                <br />
                <br />
                <span className="span-heading">Class:- </span> <br />
                In this term, super admin and admin can create a class to operate other’s regular academic activities. They can also create sections, edit, and delete if they need. Classes and sections help to run class routine, subject, syllabus, classroom, and department.
                <br />
                <br />
                <span className="span-heading">Classroom:- </span> <br />
                The classroom is a significant part of operating academic activities in an educational institute. Only admin and super admin can add, edit and remove classrooms, and they have an essential role in making a proper layout for the institute.
                <br />
                <br />
                <span className="span-heading">Department:- </span>
                <br />
                Every educational institute has different departments to function for academic progress. In school management ERP, super admin and admin have the authority to add, edit, and erase various departments.
                <br />
                <br />
                <span className="span-heading">Event calendar:- </span>
                <br />
                The academic calendar is an essential part of this term. It helps to make track of an academic session. Everyone can see the event and detail from this panel. In school management ERP, super admin and admin can add an event to the academic calendar.
                <br />
                <br />
                <span className="span-heading">Library Management:- </span>
                <br />
                Library management systems help libraries keep track of the books, their checkouts, and members’ subscriptions and profiles.
                <br />
                <br />
                <span className="span-heading">Student Promotion:- </span>
                <br />
                Student promotion by the student or by class with proper status.
                <br />
                <br />
                <span className="span-heading"> Expense:-</span> <br />
                Organization all-expense you can manage using this application. Have multiple reports to track company expenses properly.
                <br />
                <br />
                <span className="span-heading">Purchase:- </span> <br />
                You can track all of your purchase histories using this application so quickly. This module automatically adjusts the newly added items into your inventory. Have multiple reports to visualize the entire purchase module.
                <br />
                <br />
                <span className="span-heading">Dashboard:- </span> <br />
                Dynamic dashboard with recently registered students and recent payment items.
                <br />
                <br />
                <span className="span-heading">Reports:- </span> <br />
                Get comprehensive insights into your business with 20+ detailed reports designed for you. Overview & item stock, product quantity and damage item, general profit and/or loss report, sales, purchases, customer, suppliers, and staff user reports.
                <br />
                <br />
                <span className="span-heading">Currency Exchange:- </span> <br />
                Easily accept any Currency Denomination While making a Sale or Return.
                <br />
                <br />
                <span className="span-heading"> Custom Receipts:-</span> <br />
                Easily customize your receipts to match what you need for your business. There are 5 different configuration options for making your receipts how you want them.
                <br />
                <br />
                <span className="span-heading">Admin LTE Theme:- </span> <br />
                Use the excellent and responsive theme in a low ambient light environment to reduce eyestrain. It provides a range of responsive, reusable, and commonly used components.
                <br />
                <br />
                <span className="span-heading">Settings:- </span> <br />
                Easily manage the app with settings for logo, categories, variants, currencies, custom page access, and identity option.
                <br />
                <br />
                <span className="span-heading">Much more:- </span> <br />
                User login history, user management, email configuration, nice UI. <br />
              </p>
            </div>
          </div>

          <div className="right-content">
            <div className="form-card" data-aos="flip-right">
              <h2>Contact</h2>
              <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Name" required />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Email" required />

                <label htmlFor="phone">Phone:</label>
                <input type="tel" id="phone" name="phone" placeholder="Phone" required />

                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

                <button type="submit">Submit</button>
              </form>
            </div>
            {/* Use the Popup component */}
            {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default SchoolCollegeUniversityManagementERP;
