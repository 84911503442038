import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const HelpdeskSupportSystem = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="all-products-wrapper">
        <div className="design-container">
          <h1 data-aos="zoom-in">
            Complaint Issue Helpdesk Ticket <br /> My Ticket HelpDesk Support System
          </h1>
          <img src={Images.HTS} alt="HTS" className="images" data-aos="zoom-in" />
        </div>

        <div className="new-div">
          <div className="left-content">
            <div className="card" data-aos="zoom-in">
              <h2 className="heading2">About Complaint Issue Helpdesk Ticket My Ticket HelpDesk Support System</h2>
              <p className="explain animated-text">
                Using these web applications you can create, update delete, read, and manage customer complaints smoothly with required data reporting, cool responsive UI, and firster paging, sorting, and searching functionality. Additionally, this system will store each user’s login history properly.
                <br />
                <br />
                This application will allow you to collect all of your customer support requests from various channels and manages them from one place dynamically. You can add unlimited complaints/issues categories, status, and priority from the admin panel so that the end-users can create complaints/issues accordingly.
              </p>
            </div>

            <div className="card" data-aos="zoom-in">
              <h2 className="heading2">Features</h2>
              <div className="card-features">
                <div className="hts-feature-item animated-text">Major three modules: Complaint/User/Reporting</div>
                <div className="hts-feature-item animated-text">Modern, responsive, easy, and simple user interface to use</div>
                <div className="hts-feature-item animated-text">Fully dynamic and customized Complaint/Issue management system</div>
                <div className="hts-feature-item animated-text">MVC design pattern with clean code</div>
                <div className="hts-feature-item animated-text">ASP.NET Core, C#, Entity Framework Core, Code First approach</div>
                <div className="hts-feature-item animated-text">Roles base(Page-wise) user access permission</div>
                <div className="hts-feature-item animated-text">Create and manage Complaint/Issue</div>
                <div className="hts-feature-item animated-text">File attachment option in Complaint/Issue</div>
                <div className="hts-feature-item animated-text">Download/Delete attached file</div>
                <div className="hts-feature-item animated-text">Dynamic comment/feedback option on each Complaint/Issue</div>
                <div className="hts-feature-item animated-text">Proper notifications for every Complaint/Issue</div>
                <div className="hts-feature-item animated-text">Manage company logo, name, etc. from admin panel</div>
                <div className="hts-feature-item animated-text">Manage SMTP settings for email notification from the admin panel</div>
                <div className="hts-feature-item animated-text">Admin/User can easily close/update Complaint/Issue and have full control over it</div>
                <div className="hts-feature-item animated-text">Admin can create unlimited Complaint/Issue categories, status, and priority</div>
                <div className="hts-feature-item animated-text">User login history</div>
                <div className="hts-feature-item animated-text">Reset password with a password reset link</div>
                <div className="hts-feature-item animated-text">Complaint/Issue status pie-chart summary for the admin user</div>
                <div className="hts-feature-item animated-text">Powerful admin panel and reporting system</div>
                <div className="hts-feature-item animated-text">Dynamic custom grids with column orders, faster data loading, pagination, and many more</div>
              </div>
            </div>

            <div className="card" data-aos="zoom-in">
              <h2 className="heading2">What The Project Means For Us</h2>
              <p className="explain animated-text">This application simplifies the management of service requests and appointments, improving overall operational efficiency. By offering a streamlined booking process and real-time tracking, it enhances customer satisfaction and helps businesses deliver high-quality services.</p>
            </div>
          </div>

          <div className="right-content">
            <div className="form-card" data-aos="flip-right">
              <h2>Contact</h2>
              <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Name" required />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Email" required />

                <label htmlFor="phone">Phone:</label>
                <input type="tel" id="phone" name="phone" placeholder="Phone" required />

                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

                <button type="submit">Submit</button>
              </form>
            </div>
            {/* Use the Popup component */}
            {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpdeskSupportSystem;
