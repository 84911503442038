import React, { useState } from "react";
import "./style.css";
import Images from "../../Images/Images";
import submitContactForm from "../../Api/Api";
import Popup from "../../CustomPopup/CustomPopup";

const AdvancedFilesUsersManagement = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    };

    try {
      await submitContactForm(formData);
      setPopupMessage("Form submitted successfully!");

      // Clear form fields after successful submission
      event.target.reset();
    } catch (error) {
      setPopupMessage("Error submitting form. Please try again.");
    } finally {
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="all-products-wrapper">
        <div className="design-container">
          <h1 data-aos="zoom-in">
            Advanced Files <br /> & Users Management
          </h1>
          <img src={Images.AFAUM} alt="Advanced Files & Users Management" className="images" data-aos="zoom-in" />
        </div>

        <div className="new-div">
          <div className="left-content">
            <div className="card" data-aos="zoom-in">
              <h2>About Advanced Files & Users Management</h2>
              <p className="explain animated-text">
                Advanced Files & Users Management is a multi-functional complete web application developed using ASP.NET Core 6.0 and EF Core code first technology. This web application enables you to manage all kinds of documents with proper history, add interactive comments, and along with a dynamic dashboard.
                <br />
                <br />
                You can store, track, modify, and manage documents on a centralized platform. Features include document sharing, adding new comments, user management, bulk page access permissions, document download, and document preview, sending documents through email, document proper history, etc.
                <br />
                <br />
                It’s stored all types of documents in encrypted form on the server. It allows you to upload multiple documents and share them with unlimited system users. Admin can view, upload, edit, delete, add files, and control user access.
                <br />
              </p>
            </div>

            <div className="card" data-aos="zoom-in">
              <h2 className="heading2">Core Features</h2>
              <p className="explain animated-text">
                <span className="span-heading">Manage Documents: </span>
                <br />
                You can store unlimited documents by category and assign them to the system user. System users can add interactive comments as well.
                <br />
                <br />
                <span className="span-heading">Dashboard: </span>
                <br />
                Dynamic dashboard with pie-chart, recent users, and other totals. You can see category-wise document statistics.
                <br />
                <br />
                <span className="span-heading">Upload Documents: </span>
                <br />
                You can upload any type of document, download the document, and view it accordingly.
                <br />
                <br />
                <span className="span-heading">Encrypted Document: </span>
                <br />
                All documents are stored in encrypted form on the database. You have two options to store files in the server, either in the database or the root directory. Only the system can decrypt documents.
                <br />
                <br />
                <span className="span-heading">Document History: </span>
                <br />
                For any action on a document, the flow has been recorded as document history, e.g., creating a new document, adding a new comment, downloading a document, assigning a document, etc.
                <br />
                <br />
                <span className="span-heading">Admin LTE Theme: </span>
                <br />
                Used excellent and responsive theme. It provides a range of responsive, reusable, and commonly used components.
                <br />
                <br />
                <span className="span-heading">Settings: </span>
                <br />
                Easily manage the app with settings for logo, categories, status, custom page access, and identity options.
                <br />
                <br />
                <span className="span-heading">User & Role Management: </span>
                <br />
                Admin can manage users and page access for others in the system. Default users can access only a few pages.
                <br />
                <br />
                <span className="span-heading">Much More: </span>
                <br />
                User login history, user management, email configuration, and a nice responsive UI.
                <br />
              </p>
            </div>
          </div>

          <div className="right-content">
            <div className="form-card" data-aos="flip-right">
              <h2>Contact</h2>
              <form action="" method="post" className="contactForm" id="MindMarq" onSubmit={handleSubmit}>
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Name" required />

                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Email" required />

                <label htmlFor="phone">Phone:</label>
                <input type="tel" id="phone" name="phone" placeholder="Phone" required />

                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>

                <button type="submit">Submit</button>
              </form>
            </div>
            {/* Use the Popup component */}
            {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdvancedFilesUsersManagement;
